import React from "react";

import { useLocalize } from "@submittable/ui-components";
import _ from "lodash";
// Redux
import { useDispatch } from "react-redux";
import { useRequestMembersQuery } from "../../redux/dashboards-slice";
import { appendSelectors } from "../../redux/selector-slice";
// Components
import { components } from "react-select";
import { CircularProgress } from "@mui/material";
import { Icon, icons } from "@submittable/ui-components";
// Styles
import S from "../style";

export const arrowRenderer = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        icon={
          props.selectProps.menuIsOpen ? icons.DROPDOWN_UP : icons.DROPDOWN_DOWN
        }
        size={15}
      />
    </components.DropdownIndicator>
  );
};

const DropdownSelector = ({ name, variable_name, dashboard_id }) => {
  const dispatch = useDispatch();
  const selectOneLocalized = useLocalize`Select one ...`;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: "Proxima Nova",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontFamily: "Proxima Nova",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
    option: (provided, state) => {
      const isFirstOption = state.options.indexOf(state.data) === 0;
      return {
        ...provided,
        height: "38px",
        paddingLeft: "22px",
        cursor: "pointer",
        fontFamily: "Proxima Nova",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "21px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginTop: isFirstOption ? "-4px" : null,
      };
    },
    noOptionsMessage: (provided) => ({
      ...provided,
      fontFamily: "Proxima Nova",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      paddingRight: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  const body = {
    field: { uniqueName: variable_name, variable_name },
    dashboard_id,
  };
  const { data, isLoading } = useRequestMembersQuery(body);
  const sortedData = _.sortBy(data, (o) => o.value.toLowerCase()).map((d) => ({
    id: d.id,
    value: d.value,
    label: d.value,
  }));

  const handleChange = (id) => {
    dispatch(
      appendSelectors({
        name,
        variable_name,
        value: sortedData.find((s) => s.id === id).value,
        id,
      })
    );
  };

  return (
    <S.DropdownSelectorContainer>
      <S.DropdownSelectorLabel>{name}</S.DropdownSelectorLabel>
      {!isLoading && (
        <S.DropdownSelect
          components={{ DropdownIndicator: arrowRenderer }}
          styles={customStyles}
          placeholder={selectOneLocalized}
          options={sortedData}
          isSearchable={true}
          isLoading={isLoading}
          onChange={(e) => handleChange(e.id)}
        />
      )}
      {isLoading && <CircularProgress size={20} />}
    </S.DropdownSelectorContainer>
  );
};

export default DropdownSelector;
