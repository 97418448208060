// Alert.js
import React from "react";

import S from "./style";

const Notification = ({ message, onClose, type = "error" }) => {
  return (
    <S.Alert fullWidth severity={type} onClose={onClose}>
      {message}
    </S.Alert>
  );
};

export default Notification;
