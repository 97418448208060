import React from "react";

// Components
import { Typography } from "@mui/material";
// Styles
import Colors from "../../design-system/colors";
import S from "./style";

const HeaderWidget = ({ widget }) => {
  return (
    <S.Container>
      <S.InnerContainer>
        <Typography className="proSemibold18x24" color={Colors.Charcoal}>
          {widget.title}
        </Typography>
      </S.InnerContainer>
    </S.Container>
  );
};

export default HeaderWidget;
