import React from "react";

import { useLocalize } from "@submittable/ui-components";
// Components
import DashboardName from "./dashboard-name";
// Images
import chevronRight from "../../../assets/images/chevron-right.svg";
// Styles
import S from "../style";

const Header = ({ title = "", description = "", onSave }) => {
  return (
    <S.HeaderContainer>
      <S.GoBack to="/dataportal/dashboards">
        <img src={chevronRight} alt="go back" width={16} height={12} />
        <S.GoBackText>{useLocalize`Go back to List`}</S.GoBackText>
      </S.GoBack>
      <DashboardName title={title} description={description} onSave={onSave} />
      <S.Divider />
    </S.HeaderContainer>
  );
};

export default Header;
