import styled from "styled-components";
import { Alert } from "@mui/material";

const S = {};

S.Alert = styled(Alert)`
  width: 100%;
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  && {
    border-radius: 5px;
    border: 1px solid ${(props) =>
      props.type === "error" ? "#c22c37" : "#0373AB"};
    background: ${(props) => (props.type === "error" ? "#f4e6e6" : "#E3F0F7")}
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    font-feature-settings: "clig" off, "liga" off;
    /* 13x17 Regular */
    font-family: Proxima Nova;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 130.769% */
  }
`;

export default S;
