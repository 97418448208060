const Colors = {};

Colors.WhiteSmoke = "#F5F5F5";
Colors.GreyF9 = "#F9F9F9";
Colors.GreyEEE = "#EEEEEE";
Colors.White = "#fff";
Colors.Nordic = "#132a29";
Colors.NightRider = "#333333";
Colors.Mortar = "#555555";
Colors.Empress = "#737373";
Colors.VeryLightGrey = "#cccccc";
Colors.IrisBlue = "#04a1e5";
Colors.Charcoal = "#424242";
Colors.PattensBlue = "#E3F0F7";
Colors.OutrageousOrange = "#FC5B30";
Colors.Cerulean = "#0373AB";
Colors.BlueLagoon = "#015B87";

export default Colors;