import React from "react";

// Redux
import { useSelector } from "react-redux";
import {
  useGetFlexMonsterKeyQuery,
  useRequestAggregationQuery,
} from "../../redux/dashboards-slice";
import { selectSelectors } from "../../redux/selector-slice";
// Components
import { LoadingSpinner } from "@submittable/ui-components";
import TopBar from "../TopBar";
import * as FlexmonsterReact from "react-flexmonster";
// Styles
import Topic from "../style";

const sampleData = {
  series: [
    {
      data: [
        ["Project Number", "City", "Funds Awarded", "Number of People"],
        ["GRT001", "New York", 50000, 10],
        ["GRT002", "Los Angeles", 75000, 15],
        ["GRT003", "Chicago", 60000, 12],
        ["GRT004", "San Francisco", 80000, 20],
        ["GRT005", "Houston", 55000, 11],
        ["GRT006", "Miami", 68000, 14],
        ["GRT007", "Seattle", 72000, 18],
        ["GRT008", "Dallas", 53000, 9],
        ["GRT009", "Boston", 79000, 16],
        ["GRT010", "Denver", 65000, 13],
        ["GRT011", "Phoenix", 60000, 12],
        ["GRT012", "Atlanta", 73000, 15],
        ["GRT013", "Las Vegas", 68000, 14],
        ["GRT014", "San Diego", 82000, 19],
        ["GRT015", "Detroit", 51000, 10],
        ["GRT016", "Minneapolis", 69000, 14],
        ["GRT017", "Portland", 72000, 16],
        ["GRT018", "New Orleans", 58000, 12],
        ["GRT019", "Philadelphia", 77000, 17],
        ["GRT020", "Austin", 62000, 13],
      ],
      fields: [
        { name: "Project Number", type: "string" },
        { name: "City", type: "string" },
        { name: "Funds Awarded", type: "number" },
        { name: "Number of People", type: "number" },
      ],
    },
  ],
};

const FlexMonster = ({ widget, onExport }) => {
  const [loading, setLoading] = React.useState(true);
  const [showToolbar, setShowToolbar] = React.useState(true);
  const chartRef = React.useRef(null);

  const selectorsSelection = useSelector(selectSelectors);
  const body = {
    dashboard_id: widget.dashboard_id,
    widget_id: widget.id,
    selections:
      selectorsSelection && selectorsSelection.length > 0
        ? selectorsSelection.map((selection) => ({
            variable_name: selection.variable_name,
            selection: selection.name,
          }))
        : [],
  };
  const { data: flexMonsterKeyData, loading: flexMonsterKeyLoading } =
    useGetFlexMonsterKeyQuery();
  const { data, isFetching } = useRequestAggregationQuery(body);
  //TODO: remove this when data is ready
  console.log(data);
  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs = tabs.filter((tab) =>
        ["fm-tab-export", "fm-tab-fullscreen"].includes(tab.id)
      );
      return tabs;
    };
  }

  const handleToggleToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  return (
    <Topic.ChartContainer>
      <>
        <TopBar
          widget={widget}
          onToggleToolbar={handleToggleToolbar}
          isAdditionalToolbarVisible={showToolbar}
        />
        {(flexMonsterKeyLoading || isFetching) && <LoadingSpinner />}
        {!flexMonsterKeyLoading && !isFetching && (
          <Topic.ChartInnerContainer id={`chart-${widget.id}`} widget={widget}>
            {flexMonsterKeyData?.Key && (
              <FlexmonsterReact.Pivot
                key={widget.id + showToolbar}
                ref={chartRef}
                componentFolder="https://cdn.flexmonster.com/"
                toolbar={showToolbar}
                beforetoolbarcreated={customizeToolbar}
                licenseKey={flexMonsterKeyData?.Key}
                width="100%"
                height="100%"
                report={{
                  options: {
                    grid: {
                      type: "flat",
                      showTotals: "off",
                      showGrandTotals: "off",
                      showHierarchies: false,
                      showReportFiltersArea: false,
                      showAutoCalculationBar: false,
                      showHeaders: true,
                      showFilter: false,
                      dragging: false,
                    },
                    configuratorButton: false,
                    configuratorActive: false,
                    showAggregations: false,
                    drillThrough: false,
                    showCalculatedValuesButton: false,
                    showDrillThroughConfigurator: false,
                    sorting: "off",
                  },
                  // dataSource: {
                  //   data: data?.series[0].data,
                  //   fields: data?.series[0].fields,
                  // },
                  dataSource: {
                    data: sampleData.series[0].data,
                    fields: sampleData.series[0].fields,
                  },
                  formats: [
                    {
                      name: "",
                      maxDecimalPlaces: 2,
                    },
                  ],
                }}
                reportcomplete={() => setLoading(false)}
              />
            )}
            {(!flexMonsterKeyData?.Key || loading || isFetching) && (
              <LoadingSpinner />
            )}
          </Topic.ChartInnerContainer>
        )}
      </>
    </Topic.ChartContainer>
  );
};

export default FlexMonster;
