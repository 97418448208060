import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./base-query-with-reauth";

// TODO: Fix this so not hardcoded
const apiUrl = process.env.REACT_APP_SBM_API ?? `https://submittable-api.submittable.com`;

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getMyUser: builder.query({
      query: () => ({
        url: `${apiUrl}/beta/my/User`,
        method: "GET",
      }),
    }),
    getUserNames: builder.mutation({
      query: ({ userIds }) => ({
        url: `${apiUrl}/v3/organizations/team/list`,
        method: "POST",
        body: JSON.stringify({ userIds }),
      }),
    }),
  }),
});

export const { useGetMyUserQuery, useGetUserNamesMutation } = usersApi;
