import Select from "react-select";
import styled from "styled-components";
import Colors from "../design-system/colors";

const S = {};

S.ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  border: 1px solid ${Colors.VeryLightGrey};
  border-radius: 3px;
  position: relative;
`;

S.ChartInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  // margin-top: ${(props) =>
    props.widget?.type === "BAR_CHART" ? 0 : "25px"};
  margin-top: 25px;
`;

S.TopBar = styled.div`
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
`;

S.TopBarInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 6px;
  box-sizing: border-box;
  position: relative;
`;

S.MenuIcon = styled.div`
  cursor: pointer;
`;

S.Menu = styled.div`
  width: 150px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${Colors.NightRider};
  background: ${Colors.White};
  border-radius: 3px;
  padding-bottom: 3px;
`;

S.MenuItem = styled.div`
  width: 100%;
  height: 34px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? Colors.PattensBlue : Colors.NightRider};

  &:hover {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: ${(props) =>
      props.disabled ? Colors.PattensBlue : Colors.NightRider};
  }
`;

S.DownloadMenuItem = styled.div`
  width: 100%;
  height: 34px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  color: ${Colors.NightRider};
  /* 15x16 Semibold */
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

S.MenuSeparator = styled.div`
  width: 148px;
  border-bottom: 1px solid ${Colors.WhiteSmoke};
`;

S.DropdownSelectorContainer = styled.div`
  width: 22vw;
  @media (max-width: 600px) {
    width: 75vw;
  }
`;

S.DropdownSelectorLabel = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 6px;
`;

// Dropdown Name Selector
S.DropdownSelect = styled(Select)``;

// No Data
S.NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
`;

S.NoDataMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default S;
