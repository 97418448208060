import React, { useEffect } from "react";

import dayjs from "dayjs";
// Components
import { LoadingSpinner, useLocalize } from "@submittable/ui-components";
import { Typography } from "@mui/material";
import { sendSnowplowEvent } from "../../../helpers/snowplowAnalytics";
// redux
import { useSelector } from "react-redux";
import { useGetDashboardsQuery } from "../../../redux/dashboards-slice";
import { useGetUserNamesMutation } from "../../../redux/user-slice";
// Images
import logo from "../../../assets/images/submittable-icon.svg";
// Styles
import S from "../style";
import Colors from "../../../design-system/colors";

const DashboardsPredefined = (dashboardCategory) => {
  // Get the localized strings outside of JSX
  const localizedType = useLocalize`Type`;
  const localizedNoData = useLocalize`No Data`;
  const localizedDateCreated = useLocalize`Date Created`;
  const localizedCreatedBy = useLocalize`Created By`;
  const localizedTitle = useLocalize`Title`;
  const dashboardPrefix = dashboardCategory['dashboardCategory'] === 'predefined' ? '/dataportal/dashboards' : '/dataportal/dashboards/custom'

  const { data, isLoading } = useGetDashboardsQuery(dashboardCategory);
  const [getUserNames, { data: userNames }] = useGetUserNamesMutation();
  const auth = useSelector((state) => state.auth);
  const noDashboardsAvailableLocalized = useLocalize`No dashboards available`;

  useEffect(() => {
    if (data?.length > 0 && auth.jwt) {
      getUserNames({
        userIds: data
          .filter((dashboard) => dashboard.created_by !== null)
          .map((dashboard) => dashboard.created_by),
      });

      sendSnowplowEvent({ data, length: data?.length }, "dashboards-loaded");
    }
  }, [data, auth, getUserNames]);

  return (
    <S.DashboardsContainer>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <S.TableContainer>
          <S.Table>
            <S.TableHeader>
              <S.TableHeaderRow>
                <S.TitleHeader>
                  <Typography
                    className="proSemibold12x16"
                    color={Colors.Mortar}
                  >
                    {localizedTitle}
                  </Typography>
                </S.TitleHeader>
                <S.TypeHeader>
                  <Typography
                    className="proSemibold12x16"
                    color={Colors.Mortar}
                  >
                    {localizedType}
                  </Typography>
                </S.TypeHeader>
                <S.DateCreatedHeader>
                  <Typography
                    className="proSemibold12x16"
                    color={Colors.Mortar}
                  >
                    {localizedDateCreated}
                  </Typography>
                </S.DateCreatedHeader>
                <S.CreatedByHeader>
                  <Typography
                    className="proSemibold12x16"
                    color={Colors.Mortar}
                  >
                    {localizedCreatedBy}
                  </Typography>
                </S.CreatedByHeader>
              </S.TableHeaderRow>
            </S.TableHeader>
            <tbody>
              {data?.length === 0 ? (
                // Show a row with "No dashboards found" message
                <tr>
                  <S.NoTableFoundDivision colSpan={4}>
                    <S.NoDashboardsFound>
                      <img src={logo} alt="logo" width={17} height={23} />
                      <Typography
                        className="proRegular15x16"
                        color={Colors.Empress}
                      >
                        {noDashboardsAvailableLocalized}
                      </Typography>
                    </S.NoDashboardsFound>
                  </S.NoTableFoundDivision>
                </tr>
              ) : (
                // Show regular rows with dashboard information
                data?.map((dashboard, index) => (
                  <S.TableRow
                    key={dashboard.id}
                    last={index === data.length - 1}
                  >
                    <S.Title>
                      <S.TitleLink
                        linkText={dashboard.title}
                        to={`${dashboardPrefix}/${dashboard.id}`}
                      ></S.TitleLink>
                    </S.Title>
                    <S.Type>
                      <Typography
                        className="proRegular13x17"
                        color={Colors.Charcoal}
                      >
                        {dashboard.type || localizedNoData}
                      </Typography>
                    </S.Type>
                    <S.CreatedAt>
                      <Typography
                        className="proRegular13x17"
                        color={Colors.Charcoal}
                      >
                        {dayjs(dashboard.created_at).format("MMM D, YYYY")}
                      </Typography>
                    </S.CreatedAt>
                    <S.CreatedBy>
                      <Typography
                        className="proRegular13x17"
                        color={Colors.Charcoal}
                      >
                        {userNames?.find((x) => x.id === dashboard.created_by)
                          ?.name || "Submittable"}
                      </Typography>
                    </S.CreatedBy>
                  </S.TableRow>
                ))
              )}
            </tbody>
          </S.Table>
        </S.TableContainer>
      )}
    </S.DashboardsContainer>
  );
};

export default DashboardsPredefined;
