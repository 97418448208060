import React, { useState } from "react";

// Components
import { Tabs, tabThemes, useLocalize } from "@submittable/ui-components";
import SingleAudit from "./components/single-audit";
import { Typography } from "@mui/material";
// Styles
import Notification from "../notification";
import S from "./style";

const Exports = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const exportsLocalized = useLocalize`Exports`;
  const [error, setError] = useState();

  const handleOnError = (message) => {
    setError(message);
  };
  const TAB_DATA = [
    {
      label: useLocalize`Audit Prep`,
      content: <SingleAudit onError={handleOnError} message={error} />,
      id: "audit-prep",
    },
  ];

  return (
    <S.Container>
      <S.ExportsTitleContainer>
        {!error && <S.ExportsTitle>
          <Typography className="proSemibold18x24">
          {exportsLocalized}
          </Typography>
          </S.ExportsTitle>}
        {error && (
          <S.ExportsTitle>
            <Notification message={error} onClose={() => setError(undefined)} />
          </S.ExportsTitle>
        )}
        <S.ExportsMyDownloads
          to="/dataportal/auditprep/downloads"
          linkText="My Downloads"
        ></S.ExportsMyDownloads>
      </S.ExportsTitleContainer>
      <S.ContentsContainer>
        <Tabs
          tabData={TAB_DATA}
          tabTheme={tabThemes.underline}
          onSelect={setSelectedIndex}
          defaultIndex={selectedIndex}
        />
      </S.ContentsContainer>
    </S.Container>
  );
};

export default Exports;
