import styled from "styled-components";

const S = {}

S.Container = styled.div`
    width: 100%;
    height: 100%;
`;

S.InnerContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
`;

S.PageContent = styled.div`
    height: 100%;
    width: 100%;
    padding-left: 60px;
    padding-right: 30px;
`;

export default S;