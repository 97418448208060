import React from "react";
// Router
import { Outlet } from "react-router-dom";
// Components
import NavBar from "./components/nav-bar";
import SideNav from "./components/side-nav";
// Styles
import S from "./style";

const AdminLayout = ({ children }) => {
  return (
    <S.Container>
      <NavBar />
      <S.InnerContainer>
        <SideNav />
        <S.PageContent>{children || <Outlet />}</S.PageContent>
      </S.InnerContainer>
    </S.Container>
  );
};

export default AdminLayout;
