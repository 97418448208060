import React, { useState } from "react";

// Components
import { Typography } from "@mui/material";
import {
  ExternalLink,
  InternalLink,
  useLocalize,
} from "@submittable/ui-components";
// Images
import chevronCollapsed from "../../../assets/images/chevron-collapsed.svg";
import chevronExpanded from "../../../assets/images/chevron-expanded.svg";
import dashboardsImg from "../../../assets/images/dashboards.svg";
// import exportsImg from "../../../assets/images/export-icon.svg";
import helpImg from "../../../assets/images/help.svg";
// Styles
import Colors from "../../../design-system/colors";
import S from "./style";

const SideNav = () => {
  const [collapsed, setCollapsed] = useState(true);
  const dashboardsLocalized = useLocalize`Dashboards`;
  const helpLocalized = useLocalize`Help`;
  // const exportsLocalized = useLocalize`Exports`;

  return (
    <S.Container collapsed={collapsed}>
      <S.ExpandCollapseButton
        onClick={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
      >
        <img
          src={collapsed ? chevronCollapsed : chevronExpanded}
          alt={collapsed ? "expand" : "collapse"}
          width="22px"
          height="22px"
        />
      </S.ExpandCollapseButton>

      <S.DashboardsItemContainer
        collapsed={collapsed}
        onClick={() => setCollapsed(true)}
      >
        <InternalLink
          to="/dataportal/dashboards"
          shouldLocalize
          className="no-underline"
        >
          <S.MenuItemContainer collapsed={collapsed}>
            <img
              src={dashboardsImg}
              alt="dashboards"
              width="18px"
              height="18px"
            />
            {!collapsed && (
              <Typography className="proRegular13x17" color={Colors.NightRider}>
                {dashboardsLocalized}
              </Typography>
            )}
          </S.MenuItemContainer>
        </InternalLink>
      </S.DashboardsItemContainer>
      {/* <S.ExportsItemContainer
        collapsed={collapsed}
        onClick={() => setCollapsed(true)}
      >
        <InternalLink to="/dataportal/auditprep/exports">
          <S.MenuItemContainer collapsed={collapsed}>
            <img src={exportsImg} alt="dashboards" width="18px" height="18px" />
            {!collapsed && (
              <Typography className="proRegular13x17" color={Colors.NightRider}>
                {exportsLocalized}
              </Typography>
            )}
          </S.MenuItemContainer>
        </InternalLink>
      </S.ExportsItemContainer> */}

      <S.HelpItemContainer
        onClick={() => setCollapsed(true)}
        collapsed={collapsed}
      >
        <ExternalLink
          to="https://www.submittable.com/help/organization"
          shouldLocalize
          className="no-underline"
        >
          <S.MenuItemContainer
            collapsed={collapsed}
            ariaLabel="Organization Help"
          >
            <img src={helpImg} alt="help" width="18px" height="18px" />
            {!collapsed && (
              <Typography className="proRegular13x17" color={Colors.NightRider}>
                {helpLocalized}
              </Typography>
            )}
          </S.MenuItemContainer>
        </ExternalLink>
      </S.HelpItemContainer>
    </S.Container>
  );
};

export default SideNav;
