import styled from "styled-components";
import Colors from "./design-system/colors";

const S = {};

S.Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
`;

S.Icon = styled.div`
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${Colors.GreyEEE};
  }
`;

export default S;
