import styled from "styled-components";

const S = {}

S.Container = styled.div`
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

S.WidgetContainer = styled.div``;

export default S;