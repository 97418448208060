import React from "react";

// Styles
import S from "./style";

const DividerWidget = ({ widget }) => {
  return (
    <S.Container>
    </S.Container>
  );
};

export default DividerWidget;
