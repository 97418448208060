import React from "react";

// Redux
import { useSelector } from "react-redux";
import { useRequestAggregationQuery } from "../../redux/dashboards-slice";
import { selectSelectors } from "../../redux/selector-slice";
// Components
import { Typography } from "@mui/material";
import { LoadingSpinner, useLocalize } from "@submittable/ui-components";
import TopBar from "../TopBar";
// Styles
import Colors from "../../design-system/colors";
import S from "./style";

const NumberTile = ({ widget }) => {
  const selected = useSelector(selectSelectors);
  const noDataLocalized = useLocalize`No Data`;
  const body = {
    dashboard_id: widget.dashboard_id,
    widget_id: widget.id,
    selections:
      Object.keys(selected)?.length > 0
        ? Object.keys(selected)?.map((selection) => ({
            variable_name: selected[selection].variable_name,
            selection: selected[selection].id,
          }))
        : [],
  };
  const { data, isFetching } = useRequestAggregationQuery(body);
  const firstDataItemValue = data?.series?.[0]?.data?.[0];
  const firstDataItemName = data?.series?.[0]?.name;
  const value = `${firstDataItemValue || ""} ${firstDataItemName || ""}`.trim();

  const handleOnExport = (kind) => {
    const widgetElement = document.getElementById(widget.id);
    if (widgetElement) {
      const canvas = document.createElement("canvas");
      canvas.width = widgetElement.offsetWidth;
      canvas.height = widgetElement.offsetHeight;
      const context = canvas.getContext("2d");
      context.fillStyle = Colors.WhiteSmoke;
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.font = "36px Proxima Nova";
      context.fillStyle = Colors.Mortar;
      context.fillText(value, canvas.width / 2, canvas.height / 2 - 20);
      context.font = "12px Proxima Nova";
      context.fillText(widget.title, canvas.width / 2, canvas.height / 2 + 20);

      const dataUrl = canvas.toDataURL("image/" + kind);

      const link = document.createElement("a");
      link.download = widget.title + "." + kind;
      link.href = dataUrl;

      link.click();
    }
  };
  return (
    <S.Container>
      {isFetching && <LoadingSpinner />}
      {!isFetching && (
        <>
          <TopBar onExport={handleOnExport} widget={widget} />
          <S.InnerContainer>
            <S.Value>
              {value && (
                <Typography className="proRegular36x36" color={Colors.Mortar}>
                  {value}
                </Typography>
              )}
              {!value && (
                <Typography className="proRegular36x36" color={Colors.Mortar}>
                  {noDataLocalized}
                </Typography>
              )}
            </S.Value>
            <S.Title>
              <Typography className="proRegular15x21" color={Colors.Mortar}>
                {widget.title}
              </Typography>
            </S.Title>
          </S.InnerContainer>
        </>
      )}
    </S.Container>
  );
};

export default NumberTile;
