import { endpoint } from "../lib/constants";

export function gotoManagerLogin() {
  const managerUrl = `${endpoint}/login?returnUrl=${encodeURIComponent(
    window.location.pathname
  )}`;
  document.location.href = managerUrl;
}

export function gotoManagerLogout() {
  const managerUrl = `${endpoint}/logout`;
  document.location.href = managerUrl;
}
