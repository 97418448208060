/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
// Components
import {
  Button,
  Dropdown,
  ExternalLink,
  Modal,
  TextInput,
  icons,
  useLocalize,
} from "@submittable/ui-components";
// Images
import deleteImg from "../../assets/images/delete-icon.svg";
import downloadAvailableImg from "../../assets/images/download-available.svg";
import downloadDisabledImg from "../../assets/images/download-icon-disabled.svg";
import downloadImg from "../../assets/images/download-icon.svg";
import menuImg from "../../assets/images/menu-icon.svg";

// Styles
import { CircularProgress, LinearProgress } from "@mui/material";
import {
  useDeleteReportMutation,
  useGetByIdQuery,
  useGetQuery,
  useUpdateMutation,
} from "../../redux/single-audit-slice";
import G from "../../style";
import S from "./style";

const statuses = {
  Failed: "Download Error.",
  Completed: "Download Available.",
};
const Row = ({ id, total, index }) => {
  const [confirmation, setConfirmation] = useState(false);
  const [renameMode, setRenameMode] = useState(false);
  const [newName, setNewName] = useState();
  const { data: report, refetch: refetchById } = useGetByIdQuery(id);
  const [updateReport, { isSuccess: isUpdateSuccess }] = useUpdateMutation();

  const localizedDownloadError = useLocalize`Download Error.`;

  const renameLocalized = useLocalize`Rename File`;

  const [deleteReport, { isLoading, isSuccess: isDeleteSuccess }] =
    useDeleteReportMutation();
  const { refetch } = useGetQuery();

  useEffect(() => {
    if (isDeleteSuccess) {
      refetch();
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetchById(id);
    }
  }, [isUpdateSuccess]);

  const getStatusText = (report) => {
    if (!report?.status) {
      return localizedDownloadError;
    }

    let statusText = statuses[report?.status];
    if (statusText) {
      return statusText;
    }
    // If in-progress calculate progress percentage and change the UI

    if (report.status === "In-progress") {
      statusText = `${Math.floor(
        (report.num_submissions_processed / report.num_of_submissions) * 100
      )}% completed`;
      return statusText;
    }

    return statusText;
  };

  const getPercentCompleted = (report) => {
    if (report?.num_submissions_processed && report?.num_of_submissions) {
      return Math.floor(
        (report.num_submissions_processed / report.num_of_submissions) * 100
      );
    }

    return 0;
  };

  const handleDeleteReport = () => {
    if (report?.status === "In-progress") {
      setConfirmation(true);
      return;
    }
    deleteReport(id);
  };

  const handleOnConfirmDelete = () => {
    deleteReport(id);
    setConfirmation(false);
  };

  const handleOnRenameReport = () => {
    updateReport({
      id: report.id,
      body: {
        ...report,
        name: newName,
      },
    });
    setRenameMode(false);
  };

  return (
    <S.TableRow key={report?.id} last={index === total - 1}>
      {!renameMode && <S.Title>{report?.name}</S.Title>}
      {renameMode && (
        <S.EditTitle>
          <TextInput
            value={newName || report?.name}
            onChange={(e) => setNewName(e.target.value)}
          />
          <S.RenameButtons>
            <Button
              buttonText={"Cancel"}
              buttonClass="cancel-rename-button"
              type="secondary"
              onClick={() => setRenameMode(false)}
            />
            <Button
              buttonClass="save-rename-button"
              buttonText={"Save"}
              type={"primary"}
              onClick={handleOnRenameReport}
            />
          </S.RenameButtons>
        </S.EditTitle>
      )}
      <S.Type>{report?.type}</S.Type>
      <S.CreatedAt>
        {dayjs(report?.created_at).format("MMM D, YYYY")}
      </S.CreatedAt>
      <S.Status status={report?.status}>
        {isLoading && <CircularProgress />}
        {report?.status === "In-progress" && (
          <LinearProgress
            sx={{ width: "50%", height: '10px', color: '#0373AB', backgroundColor: '#FFF', border: "1px solid #CCC" }}
            value={getPercentCompleted(report)}
            variant="determinate"
          ></LinearProgress>
        )}

        <span>{getStatusText(report)}</span>
        {report?.status === "Completed" && (
          <G.Icon>
            <img src={downloadAvailableImg} alt="available" />
          </G.Icon>
        )}
      </S.Status>
      <S.ActionHeader>
        <G.Icon onClick={handleDeleteReport}>
          <img src={deleteImg} alt="delete" />
        </G.Icon>
      </S.ActionHeader>
      <S.ActionHeader>
        <ExternalLink linkText={""} to={report?.report_link}>
          {report?.report_link ? (
            <img src={downloadImg} alt="download" />
          ) : (
            <img src={downloadDisabledImg} alt="disabled" />
          )}
        </ExternalLink>
      </S.ActionHeader>
      <S.ActionHeader>
        <Dropdown
          label={""}
          openLeft={true}
          labelComponent={
            <G.Icon>
              <img src={menuImg} alt="delete" />
            </G.Icon>
          }
          hideCarret={true}
        >
          <S.MenuItem onClick={() => setRenameMode(true)}>
            {renameLocalized}
          </S.MenuItem>
        </Dropdown>
      </S.ActionHeader>
      {confirmation && (
        <Modal icon={icons.DELETE} showBackdrop={true}>
          <S.ConfirmationText>
            Are you sure you want to Stop this export?
          </S.ConfirmationText>
          <S.ConfirmationText>
            Your progress will not be saved.
          </S.ConfirmationText>
          <S.ConfirmationActions>
            <Button
              buttonClass="cancel-button"
              buttonText={"Cancel"}
              type={"secondary"}
              onClick={() => setConfirmation(false)}
            />
            <Button
              buttonClass="yes-button"
              buttonText={"Yes, Stop"}
              type={"primary"}
              onClick={handleOnConfirmDelete}
            />
          </S.ConfirmationActions>
        </Modal>
      )}
    </S.TableRow>
  );
};

export default Row;
