import React, { useEffect } from "react";

import { useSbmIntercom } from "./shared/hooks/useSbmIntercom";
// Analytics
import TagManager from "react-gtm-module";
import initSnowplow from "./helpers/snowplowAnalytics";
// Redux
import { useSelector } from "react-redux";
// Components
import AdminLayout from "./layouts/Admin";
// Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./lib/routes";
// Highcharts
import Highcharts from "highcharts";
import HCExporting from "highcharts/modules/exporting";
import HCOfflineExporting from "highcharts/modules/offline-exporting";
import HCExportingCSV from "highcharts/modules/export-data";
// Styles
import "@submittable/ui-components/dist/index.css";
import "./app.css";
import S from "./style";

HCExporting(Highcharts);
HCOfflineExporting(Highcharts);
HCExportingCSV(Highcharts);

const tagManagerArgs = {
  gtmId: "GTM-PZDKQ3N",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const auth = useSelector((state) => state.auth);
  const [snowplowInitialized, setSnowplowInitialized] = React.useState(false);
  useSbmIntercom();

  useEffect(() => {
    if (auth.jwt && !snowplowInitialized) {
      initSnowplow(auth.jwt);
      setSnowplowInitialized(true);
    }
  }, [snowplowInitialized, auth.jwt]);

  return (
    <S.Container>
      <BrowserRouter>
        <Routes>
          <Route element={<AdminLayout />}>
            {routes.filter((route) => route.namespace === "user")
              .map((route) => (
                <Route key={route} {...route} />
              ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </S.Container>
  );
}

export default App;
