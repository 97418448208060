import React, { useEffect, useMemo } from "react";

import { sendSnowplowEvent } from "../../helpers/snowplowAnalytics";
// Router
import { useParams } from "react-router-dom";
// Components
import { LoadingSpinner } from "@submittable/ui-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import BarChart from "../../shared/BarChart";
import LineChart from "../../shared/LineChart";
import ColumnChart from "../../shared/ColumnChart";
import FlexMonster from "../../shared/FlexMonster";
import NumberTile from "../../shared/NumberTile";
import PieChart from "../../shared/PieChart";
import SelectorTile from "../../shared/SelectorTile";
import DividerWidget from "../../shared/divider-widget";
import HeaderWidget from "../../shared/header-widget";
import Header from "./components/Header";
// import data from "../../data/get-dashboard-id";
// Hooks
import { useGetDashboardQuery } from "../../redux/dashboards-slice";
// Styles‚
import { Stack } from "@mui/system";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DropdownSelector from "../../shared/Dropdown/dropdown-selector";
import S from "./style";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const Dashboard = ({ dashboardCategory }) => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetDashboardQuery({
    id,
    dashboardCategory,
  });

  useEffect(() => {
    if (data) {
      sendSnowplowEvent("dashboard", "view", data.title);
    }
  }, [data]);

  const layout = useMemo(() => {
    return data?.widgets?.map((item) => ({
      i: item.id,
      x: item.position_x,
      y: getY(item),
      w: item.position_w,
      h: getH(item),
    }));
  }, [data]);

  function getY(item) {
    return item.position_y * 15;
  }

  function getH(item) {
    if (item.type === "NUMBER_TILE") {
      return 8; // = 150px
    }
    if (item.type === "HORIZONTAL_HEADER") {
      return 2; // = 20px
    }
    if (item.type === "HORIZONTAL_DIVIDER") {
      return 1; // = 10px
    }
    return item.position_h * 7.5;
  }

  if (isError) {
    return null;
  }

  if (!data) {
    return <LoadingSpinner />;
  } else if (id !== data.id) {
    return <S.NotFound>Dashboard not found</S.NotFound>;
  }

  const handleOnExport = (chartRef, kind, widget) => {
    sendSnowplowEvent("export dashboard", "export", widget.title, kind);
    try {
      switch (kind) {
        case "svg":
          chartRef.exportChart({
            type: "image/svg+xml",
          });
          break;
        case "png":
          chartRef.exportChart({
            type: "image/png",
          });
          break;
        case "pdf":
          chartRef.exportChart({
            type: "application/pdf",
          });
          break;
        case "jpeg":
          chartRef.exportChart({
            type: "image/jpeg",
          });
          break;
        case "xls":
          const xls = chartRef.getTable();
          const linkXLS = document.createElement("a");
          linkXLS.href = "data:application/vnd.ms-excel;base64," + btoa(xls);
          linkXLS.download = `chart-${widget.title}.xls`;
          linkXLS.target = "_blank";
          document.body.appendChild(linkXLS);
          linkXLS.click();
          document.body.removeChild(linkXLS);
          break;
        case "csv":
          const csv = chartRef.getCSV();
          const linkCSV = document.createElement("a");
          linkCSV.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
          linkCSV.download = `chart-${widget.title}.csv`;
          linkCSV.target = "_blank";
          document.body.appendChild(linkCSV);
          linkCSV.click();
          document.body.removeChild(linkCSV);
          break;
        default:
          break;
      }
    } catch (e) { }
  };

  return (
    <S.Container>
      <Header title={data?.title} description={data?.description} />
      {isLoading && <LoadingSpinner />}
      {!isLoading && !isError && data && (
        <>
          <Stack spacing={2} direction={["column", "row"]}>
            {data?.selectors
              ?.filter((s) => s.variable_name && s.name)
              .slice(0, 4)
              .map((item) => (
                <S.DropdownName key={`${id}${item.variable_name}${item.name}`}>
                  <DropdownSelector
                    name={item.name}
                    variable_name={item.variable_name}
                    dashboard_id={id}
                  />
                </S.DropdownName>
              ))}
            <S.DataRefreshLabel>
              {`Data Refreshed: ${data?.dataUpdatedAt}`}
            </S.DataRefreshLabel>
          </Stack>
          <S.InnerContainer>
            <ResponsiveReactGridLayout
              className="layout"
              isDraggable={false} // {data?.editable || false}
              isResizable={false} // {data?.editable || false}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 6, sm: 4, xs: 2, xxs: 1 }}
              // TODO: Adjust X and Y based on this row height. this will affect Y or height.
              rowHeight={10}
              layouts={{
                lg: layout,
                md: layout,
                sm: layout,
                xs: layout,
                xxs: layout,
              }}
            >
              {data?.widgets.map((widget, i) => {
                return (
                  <div
                    key={widget.id}
                    id={widget.id}
                    data-grid={{
                      w: widget.position_w,
                      h: getH(widget),
                      x: widget.position_x,
                      y: getY(widget),
                    }}
                  >
                    {widget.type === "NUMBER_TILE" && (
                      <NumberTile
                        widget={{ ...widget, dashboard_id: data.id }}
                      />
                    )}
                    {widget.type === "TABLE" && (
                      <FlexMonster
                        widget={{ ...widget, dashboard_id: data.id }}
                        onExport={(chartRef, kind) =>
                          handleOnExport(chartRef, kind, widget)
                        }
                      />
                    )}
                    {widget.type === "HORIZONTAL_HEADER" && (
                      <HeaderWidget
                        widget={{ ...widget, dashboard_id: data.id }}
                      />
                    )}
                    {widget.type === "HORIZONTAL_DIVIDER" && <DividerWidget />}
                    {widget.type === "BAR_CHART" && (
                      <BarChart
                        widget={{ ...widget, dashboard_id: data.id }}
                        onExport={(chartRef, kind) =>
                          handleOnExport(chartRef, kind, widget)
                        }
                      />
                    )}
                    {widget.type === "LINE_CHART" && (
                      <LineChart
                        widget={{ ...widget, dashboard_id: data.id }}
                        onExport={(chartRef, kind) =>
                          handleOnExport(chartRef, kind, widget)
                        }
                      />
                    )}
                    {widget.type === "COLUMN_CHART" && (
                      <ColumnChart
                        widget={{ ...widget, dashboard_id: data.id }}
                        onExport={(chartRef, kind) =>
                          handleOnExport(chartRef, kind, widget)
                        }
                      />
                    )}
                    {widget.type === "PIE_CHART" && (
                      <PieChart
                        widget={{ ...widget, dashboard_id: data.id }}
                        onExport={(chartRef, kind) =>
                          handleOnExport(chartRef, kind, widget)
                        }
                      />
                    )}
                    {widget.type === "SELECTOR" && (
                      <SelectorTile widget={widget} />
                    )}
                  </div>
                );
              })}
            </ResponsiveReactGridLayout>
          </S.InnerContainer>
        </>
      )}
    </S.Container>
  );
};

export default Dashboard;
