import { configureStore } from "@reduxjs/toolkit";
import selectorReducer from "./selector-slice";
import authReducer from "./auth-slice";
import { dashboardsApi } from "./dashboards-slice";
import { usersApi } from "./user-slice";
import singleAuditReducer, { singleAuditApi } from "./single-audit-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    singleAudit: singleAuditReducer,
    selector: selectorReducer,
    [dashboardsApi.reducerPath]: dashboardsApi.reducer,
    [singleAuditApi.reducerPath]: singleAuditApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(singleAuditApi.middleware)
      .concat(dashboardsApi.middleware),
});
