import React, { useState } from "react";

import { useLocalize } from "@submittable/ui-components";
// Components
import { Typography } from "@mui/material";
import { Tabs, tabThemes } from "@submittable/ui-components";
import DashboardsPredefined from "./components/dashboards-predefined";
// Styles
import Colors from "../../design-system/colors";
import S from "./style";

const Dashboards = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const labelLocalized = useLocalize`Predefined`;
  const customLocalized = useLocalize`Custom`;
  const TAB_DATA = [
    {
      label: labelLocalized,
      content: <DashboardsPredefined dashboardCategory="predefined" />,
      id: "predefined-dashboards",
    },
    {
      label: customLocalized,
      content: <DashboardsPredefined dashboardCategory="custom" />,
      id: "custom-dashboards",
    },
  ];

  return (
    <S.Container>
      <S.DashboardsTitle>
        <Typography className="proSemibold18x24" color={Colors.Mortar}>
          {useLocalize`Dashboards`}
        </Typography>
      </S.DashboardsTitle>
      <S.ListContainer>
        <Tabs
          tabData={TAB_DATA}
          tabTheme={tabThemes.underline}
          onSelect={setSelectedIndex}
          defaultIndex={selectedIndex}
        />
      </S.ListContainer>
    </S.Container>
  );
};

export default Dashboards;
