import React from "react";

// Components
import { Typography } from "@mui/material";
// Styles
import S from "../style";
import Colors from "../../../design-system/colors";

const DashboardName = ({ title, description = "", onSave }) => {
  const [editMode, setEditMode] = React.useState(false);
  const [descBrief, setDescBrief] = React.useState(true);
  const [editModeTitle, setEditModeTitle] = React.useState("");
  const [titleLength, setTitleLength] = React.useState(title?.length);
  const [newTitle, setNewTitle] = React.useState(title);
  const [newDesc, setNewDesc] = React.useState(description);
  const [descLength, setDescLength] = React.useState(description?.length);

  //post changes
  const submitEdit = (e) => {
    onSave(newTitle, newDesc);
    setEditMode(false);
    setEditModeTitle();
  };

  const handleTitleLength = (e) => {
    setTitleLength(e.target.value?.length);
    setNewTitle(e.target.value);
  };

  const handleDescLength = (e) => {
    setDescLength(e.target.value?.length);
    setNewDesc(e.target.value);
  };

  return (
    <S.DashboardNameContainer>
      <S.DashboardNameHeader>
        <S.EditDashboardNameText>
          <Typography className="proSemibold24x24" color={Colors.NightRider}>
            {editModeTitle}
          </Typography>
        </S.EditDashboardNameText>
      </S.DashboardNameHeader>
      {!editMode && (
        <div>
          <S.DashboardNameTitle>
            <Typography className="proSemibold24x24" color={Colors.NightRider}>
              {title}
            </Typography>
          </S.DashboardNameTitle>
          <S.DashboardDescContainer>
            {descBrief && description?.length <= 163 ? (
              <span>{description}</span>
            ) : descBrief && description?.length > 163 ? (
              <>
                <span>{description.slice(0, 148)}</span>
                <S.DashboardDescSpan onClick={() => setDescBrief(false)}>
                  {"Read more"}
                </S.DashboardDescSpan>
              </>
            ) : (
              !descBrief && (
                <>
                  <span>{description}</span>
                  <S.DashboardDescSpan onClick={() => setDescBrief(true)}>
                    {"less"}
                  </S.DashboardDescSpan>
                </>
              )
            )}
          </S.DashboardDescContainer>
        </div>
      )}
      {editMode && (
        <S.EditContainer>
          <S.InputGroup>
            <S.EditInputTitle
              type="text"
              onChange={handleTitleLength}
              placeholder="Name your dashboard"
              maxLength={100}
              value={newTitle}
            />
            <S.EditInputTitleCounter>{titleLength}/100</S.EditInputTitleCounter>
          </S.InputGroup>

          <S.InputGroup>
            <S.EditInputDesc
              placeholder="Description"
              value={newDesc}
              onChange={handleDescLength}
              maxLength={250}
            />
            <S.EditInputDescCounter>{descLength}/250</S.EditInputDescCounter>
          </S.InputGroup>

          <S.EditSubmit onClick={submitEdit}>Save</S.EditSubmit>
        </S.EditContainer>
      )}
    </S.DashboardNameContainer>
  );
};

export default DashboardName;
