import { Navigate } from "react-router-dom";

import Dashboards from "../components/dashboards";
import Dashboard from "../components/dashboard";
import Widget from "../components/widget";
import Home from "../components/home";
import Downloads from "../components/downloads";
import Exports from "../components/exports";

const routes = [
    {
        path: '/dataportal/dashboards/custom/:id',
        namespace: 'user',
        element: <Dashboard dashboardCategory='custom' />
    },
    {
        path: '/dataportal/dashboards/:id',
        namespace: 'user',
        element: <Dashboard dashboardCategory='predefined' />
    },
    {
        path: '/dataportal/widget',
        namespace: 'user',
        element: <Widget />
    },
    {
        path: '/dataportal/auditprep/downloads',
        namespace: 'user',
        element: <Downloads />
    },
    {
        path: '/dataportal/auditprep/exports',
        namespace: 'user',
        element: <Exports />
    },
    {
        path: '/dataportal/dashboards',
        namespace: 'user',
        element: <Dashboards />
    },
    {
        path: '/dataportal',
        namespace: 'user',
        element: <Navigate to="/dataportal/dashboards" replace />
    },
    {
        path: '/',
        exact: true,
        namespace: 'user',
        element: <Home />
    }
]

export default routes;