import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { useGetMyUserQuery } from "../../redux/user-slice";

export const useSbmIntercom = () => {
  const auth = useSelector((state) => state.auth);
  const { data } = useGetMyUserQuery(auth.jwt);

  const { boot } = useIntercom();

  useEffect(() => {
    if (data?.firstName) {
      boot({
        name: data?.firstName,
        appId: process.env.REACT_APP_INTERCOM_ID,
        hideDefaultLauncher: false,
      });
    }
  }, [boot, data]);
};
