import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { endpoint } from "../lib/constants";
import { baseQueryWithReauth } from "./base-query-with-reauth";

const apiUrl = `${endpoint}/api/dataportal`;

export const singleAuditApi = createApi({
  reducerPath: "singleAuditApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["singleaudit"],
  endpoints: (builder) => ({
    get: builder.query({
      query: () => `${apiUrl}/singleaudit`,
      keepUnusedDataFor: 5,
      providesTags: ["singleaudit"],
    }),
    getById: builder.query({
      query: (id) => `${apiUrl}/singleaudit/${id}`,
    }),
    create: builder.mutation({
      query: (body) => ({
        url: `${apiUrl}/singleaudit`,
        method: "POST",
        body,
      }),
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        url: `${apiUrl}/singleaudit/${id}`,
        method: "DELETE",
      }),
      onSuccess: (response, variables, api, context) => {
        const deletedReportId = variables;
        const state = context.getState();
        state.singleAuditApi.queries["get(undefined"].data =
          state.singleAuditApi.queries["get(undefined"].data.filter(
            (report) => report.id !== deletedReportId
          );
      },
    }),
    update: builder.mutation({
      query: ({id, body}) => ({
        url: `${apiUrl}/singleaudit/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});
const initialState = {
  exportError: undefined,
  downloadError: undefined,
};
const singleAuditSlice = createSlice({
  name: "singleAudit",
  initialState,
  reducers: {
    setExportError: (state, action) => {
      state.exportError = action.payload;
    },
    setDownloadError: (state, action) => {
      state.downloadError = action.payload;
    },
  },
});

export const { setExportError, setDownloadError } = singleAuditSlice.actions;

export const {
  useGetQuery,
  useGetByIdQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteReportMutation,
} = singleAuditApi;

export default singleAuditSlice.reducer;
