import React, { useEffect } from "react";

// Redux
import { useSelector } from "react-redux";
import { selectSelectors } from "../../redux/selector-slice";

// Components
import { LoadingSpinner } from "@submittable/ui-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoData from "../NoData";
import TopBar from "../TopBar";

// Redux
import { useRequestAggregationQuery } from "../../redux/dashboards-slice";
// Styles
import Topic from "../style";

const PieChart = ({ widget, onExport }) => {
  const chartRef = React.useRef(null);

  const selected = useSelector(selectSelectors);
  const body = {
    dashboard_id: widget.dashboard_id,
    widget_id: widget.id,
    selections:
      Object.keys(selected)?.length > 0
        ? Object.keys(selected)?.map((selection) => ({
          variable_name: selected[selection].variable_name,
          selection: selected[selection].id,
        }))
        : [],
  };
  const { data, isFetching } = useRequestAggregationQuery(body);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
    },
    plotOptions: {},
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    exporting: {
      filename: `chart-${widget.title}`,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: widget.title,
    },
    subtitle: {
      text: widget.subtitle,
    },
    xAxis: {
      categories: data?.categories || [],
      crosshair: true,
    },
    series: data?.series || [],
  };

  const handleOnExport = (kind) => {
    onExport(chartRef.current.chart, kind);
  };

  return (
    <Topic.ChartContainer>
      {isFetching && <LoadingSpinner />}
      {!isFetching && (
        <>
          {data?.series?.length > 0 ? (
            <>
              <TopBar onExport={handleOnExport} widget={widget} />
              <Topic.ChartInnerContainer>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  ref={chartRef}
                />
              </Topic.ChartInnerContainer>
            </>
          ) : (
            <NoData widget={widget} />
          )}
        </>
      )}
    </Topic.ChartContainer>
  );
};

export default PieChart;
