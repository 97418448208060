import styled, { css } from "styled-components";
import Colors from "../../design-system/colors";
import { Spacing } from "../../design-system/spacing";
import { InternalLink } from "@submittable/ui-components";

const S = {};

S.Container = styled.div`
  min-height: 100vh;
  width: 100%;
  padding-left: 62px;
`;

S.NoTableFoundDivision = styled.td``;

S.NoDashboardsFound = styled.div`
  height: 55px;
  width: 100%;
  padding-left: 23px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0;
`;
S.DashboardsTitle = styled.div`
  padding-top: calc(51px + ${Spacing.NavBarHeight});
  margin-bottom: 92px;
  color: ${Colors.Mortar};
`;

S.ListContainer = styled.div`
  width: 89%;
`;

S.DashboardsContainer = styled.div`
  padding-top: 60px;
  width: 100%;
`;

S.TableContainer = styled.div`
  width: 100%;
  border: 1px solid ${Colors.VeryLightGrey}};
  border-radius: 0px 0px 3px 3px;
`;
S.Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
`;

S.TableHeader = styled.thead`
  height: 35px;
  background: ${Colors.WhiteSmoke};
`;

S.TableHeaderRow = styled.tr`
  border-bottom: 1px solid ${Colors.VeryLightGrey};
`;

S.TitleHeader = styled.td`
  padding-left: 55px;
  min-width: 360px;
  color: ${Colors.Mortar};
`;

S.TypeHeader = styled.td`
  text-align: left;
  min-width: 225px;
  font-weight: 600;
  color: ${Colors.Mortar};
`;

S.DateCreatedHeader = styled.td`
  text-align: left;
  min-width: 300px;
  color: ${Colors.Mortar};
`;

S.CreatedByHeader = styled.td`
  text-align: left;
  padding-right: 96px;
  color: ${Colors.Mortar};
`;

S.TableRow = styled.tr`
  ${(props) =>
    !props.last &&
    css`
      border-bottom: 1px solid ${Colors.VeryLightGrey};
    `}
`;

S.Title = styled.td`
  padding-left: 16px;
  height: 100%;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 57px;
`;

S.TitleLink = styled(InternalLink)`
  color: ${Colors.Cerulean};
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  cursor: pointer;
`;

S.Type = styled.td`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  align-items: center;
  color: ${Colors.Charcoal};
  padding-bottom: 16px;
  padding-top: 16px;
`;

S.CreatedAt = styled.td`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: ${Colors.Charcoal};
`;

S.CreatedBy = styled.td`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: ${Colors.Charcoal};
`;

S.InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 32px;
`;

S.HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

S.InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default S;
