import moment from "moment";
import { createApi } from "@reduxjs/toolkit/query/react";
import { endpoint, flexMonsterKeyEndpoint, timestampFormat } from "../lib/constants";
import { baseQueryWithReauth } from "./base-query-with-reauth";

const apiUrl = `${endpoint}/api/dataportal`;

const addDataFreshness = (response, _meta, _arg) => {
  const now = moment()
  const then = now.subtract(3, 'hours')
  response['dataUpdatedAt'] = then.format(timestampFormat)
  return response
}

export const dashboardsApi = createApi({
  reducerPath: "dashboardsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["dashboards"],
  endpoints: (builder) => ({
    // QUERIES
    getDashboards: builder.query({
      query: (dashboardCategory) => dashboardCategory['dashboardCategory'] === 'predefined'
        ? `${apiUrl}/dashboards`
        : `${apiUrl}/dashboards/custom`,
      providesTags: ["dashboards"],
    }),
    getDashboard: builder.query({
      query: (data) => data.dashboardCategory === 'predefined'
        ? `${apiUrl}/dashboards/${data.id}`
        : `${apiUrl}/dashboards/custom/${data.id}`,
      transformResponse: addDataFreshness
    },
    ),
    getFlexMonsterKey: builder.query({
      query: () => `${flexMonsterKeyEndpoint}/get-flexmonster-key`,
    }),
    requestAggregation: builder.query({
      query: (body) => ({
        url: `${apiUrl}/analytics/aggregate`,
        method: "POST",
        body,
      }),
    }),
    requestMembers: builder.query({
      query: (body) => ({
        url: `${apiUrl}/analytics/members`,
        method: "POST",
        body,
      }),
    }),
    // MUTATIONS
    addDashboard: builder.mutation({
      query: (body) => ({
        url: `${apiUrl}/dashboards`,
        method: "POST",
        body,
      }),
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        // Pessimistic update
        const { data: newDashboard } = await queryFulfilled;
        dispatch(
          dashboardsApi.util.updateQueryData(
            "getDashboards",
            result.id,
            (draft) => {
              draft.push(newDashboard);
            }
          )
        );
      },
    }),
  })
});

export const {
  useGetDashboardsQuery,
  useGetDashboardQuery,
  useGetFlexMonsterKeyQuery,
  useRequestAggregationQuery,
  useRequestMembersQuery,
  useAddDashboardMutation,
} = dashboardsApi;
