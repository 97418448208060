import styled from "styled-components";

const S = {};

S.HomeContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export default S;