import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectors: {},
  statuses: [],
  labels: [],
  funds: [],
  status: "idle",
};

export const getSelectors = createAsyncThunk(
  "selector/fetchSelectors",
  async () => {}
);

export const selectorSlice = createSlice({
  name: "selector",
  initialState,
  reducers: {
    appendSelectors: (state, action) => {
        state.selectors[`${action.payload.variable_name}`] = action.payload
      
    },
    setStatuses: (state, action) => {
      state.statuses = action.payload;
    },
    setLabels: (state, action) => {
      state.labels = action.payload;
    },
    setFunds: (state, action) => {
      state.funds = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
      setTimeout(() => {
        state.status = "idle";
      }, 1000);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSelectors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSelectors.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const { appendSelectors, setStatuses, setLabels, setFunds, setStatus } =
  selectorSlice.actions;

export const selectSelectors = (state) => state.selector.selectors;
export const selectStatuses = (state) => state.selector.statuses;
export const selectLabels = (state) => state.selector.labels;
export const selectFunds = (state) => state.selector.funds;
export const selectStateStatus = (state) => state.selector.status;

export default selectorSlice.reducer;
