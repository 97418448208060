import React, { useEffect } from 'react';
// Hooks
import { useNavigate } from "react-router-dom";
// Styled
import S from './style';

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/dataportal/dashboards')
    }, [navigate])

    return (
        <S.HomeContainer>
            Redirecting to dashboard
        </S.HomeContainer>
    )
}

export default Home;