import { InternalLink } from "@submittable/ui-components";
import styled from "styled-components";
import Colors from "../../design-system/colors";
import { Spacing } from "../../design-system/spacing";

const S = {};

S.Container = styled.div`
  min-height: 100vh;
  width: 90%;
  padding-left: 62px;
`;

S.NoTableFoundDivision = styled.td``;

S.ExportsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.ExportsTitle = styled.div`
  padding-top: calc(51px + ${Spacing.NavBarHeight});
  margin-bottom: 92px;
  flex-grow: 1;
`;

S.ExportsMyDownloads = styled(InternalLink)`
  /* 15x21 Regular */
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 140% */
  color: ${Colors.Cerulean};
  cursor: pointer;
  text-decoration-line: none;
`;

S.ContentsContainer = styled.div`
  width: 100%;
`;

S.ExportsContainer = styled.div`
  padding-top: 60px;
  width: 100%;
`;

S.InnerContainer = styled.div`
  width: 100%;
`;

S.DescriptionContainer = styled.div`
  margin-bottom: 45px;
`;

S.DescriptionTitle = styled.div`
  margin-bottom: 12px;
`;

S.DescriptionText = styled.div`
  max-width: 612px;
`;

S.DropzoneContainer = styled.div`
  margin-bottom: 55px;
`;

S.FieldHead = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`;

S.Badge = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) =>
    props.disabled ? Colors.VeryLightGrey : Colors.NightRider};
  color: ${Colors.White};
`;

S.FieldHeadTitle = styled.div`
  margin-left: 8px;
  margin-right: 5px;
`;

S.InfoIcon = styled.div`
  flex-grow: 1;
`;

S.DeleteIcon = styled.div`
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${Colors.GreyEEE};
  }
`;
S.DropzoneFooter = styled.div`
  margin-top: 8px;
  color: ${Colors.Empress};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 113.333% */
`;
S.DropzoneContentContainer = styled.div`
  border: 1px solid black;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px dashed ${Colors.VeryLightGrey};
  gap: 8px;
  background: ${(props) =>
    props.isValidFile ? Colors.PattensBlue : Colors.White};
`;
S.FileName = styled.div`
  color: ${Colors.Mortar};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px; /* 113.333% */
`;

S.DragAndDropHere = styled.div`
  color: ${Colors.Mortar};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 140% */
`;

S.ChooseFile = styled.div`
  color: ${Colors.Cerulean};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-line: underline;
  cursor: pointer;
`;

S.DropzoneHeader = styled.div``;

S.NameContainer = styled.div`
  margin-bottom: 45px;
  max-width: 620px;
`;

S.FiscalYearContainer = styled.div`
  margin-bottom: 50px;
  max-width: 280px;
`;

S.ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 50px;
`;

S.PopoverContainer = styled.div`
  max-width: 240px;
`;

S.PopoverTitle = styled.div`
  color: ${Colors.BlueLagoon};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
`;

S.PopoverBody = styled.div``;

S.PopoverItem = styled.li`
  color: ${Colors.BlueLagoon};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
`;

export default S;
