import React, { useEffect, useState } from "react";

import { isNumber } from "lodash";
import moment from "moment";
import Papa from "papaparse";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize"; // This is required for react-dates to work properly
import "react-dates/lib/css/_datepicker.css"; // Import the default CSS styles
import { useNavigate } from "react-router-dom";
import { read, utils } from "xlsx";
// Redux
import { useCreateMutation } from "../../../redux/single-audit-slice";
// Components
import { Typography } from "@mui/material";
import {
  Button,
  ExternalLink,
  IconPopover,
  TextInput,
  buttonTypes,
  icons,
  useLocalizeAll,
} from "@submittable/ui-components";
import Dropzone from "react-dropzone";
// Images
import checkMarkImg from "../../../assets/images/check-mark-cerulean.svg";
import deleteImg from "../../../assets/images/delete-icon.svg";
// Styles
import Colors from "../../../design-system/colors";
import S from "../style";

const acceptedFileTypes = ["csv", "xlsx"];

const SingleAudit = ({ onError, message }) => {
  const [fileName, setFileName] = useState("");
  const [reportName, setReportName] = useState("");
  const [submissionIds, setSubmissionIds] = useState([]);
  const [date, setDate] = useState(null);
  const [focused, setFocused] = useState(false);

  const invalidFileExtensionLocalized = useLocalizeAll`Invalid file extension`;
  const invalidFileContentsLocalized = useLocalizeAll`Invalid file contents`;
  const invalidRequestLocalized = useLocalizeAll`There was an error with your export.`;
  const createAnAuditPackageLocalized = useLocalizeAll`Create an Audit Package`;
  const descriptionLocalized = useLocalizeAll`The Single Audit Package is a comprehensive download that
consolidates all essential data in a well-structured format for the
compliance section of the Single Audit Report.`;
  const uploadSubmissionLocalized = useLocalizeAll`Upload the Submission IDs file.`;
  const nameYourAuditPackageLocalized = useLocalizeAll`Name your audit package download`;
  const selectFiscalYearLocalized = useLocalizeAll`Select fiscal year end date (optional)`;
  const acceptedFormatsLocalized = useLocalizeAll`Accepted file formats: .CSV, .Xlsx`;
  const dragAndDropHereLocalized = useLocalizeAll`Drag & Drop your file here or`;
  const chooseFileLocalized = useLocalizeAll`Choose File`;
  const whereDoIFindSubmissionIdsLocalized = useLocalizeAll`Where do I find Submission ID's?`;


  const navigate = useNavigate();
  const [create, { isLoading, isSuccess, isError }] = useCreateMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate("/dataportal/auditprep/downloads");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isError) {
      onError(invalidRequestLocalized);
    }
  }, [invalidRequestLocalized, isError, onError]);

  const onDateChange = (newDate) => {
    setDate(newDate);
  };

  const onFocusChange = ({ focused }) => {
    setFocused(focused);
  };

  const isValidData = (data) => {
    let allValid = true;
    data.forEach((element) => {
      if (!isNumber(element)) {
        allValid = false;
      }
    });

    return allValid;
  };

  const handleFileParsing = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);

        if (file.name.toLowerCase().endsWith(".xlsx")) {
          // Parse XLSX file
          const workbook = read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];
          const sheetData = utils.sheet_to_json(sheet, { header: 1 });
          const ids = sheetData.map((e) => e[0]);
          if (!isValidData(ids)) {
            return onError(invalidFileContentsLocalized);
          }
          setSubmissionIds(ids);
        } else if (file.name.toLowerCase().endsWith(".csv")) {
          // Parse CSV file
          Papa.parse(file, {
            header: true, // Assumes the first row contains headers
            dynamicTyping: true, // Automatically parse numbers and booleans
            skipEmptyLines: true, // Skip empty lines
            complete: (result) => {
              const ids = result.data.map((e) => Object.values(e)[0]);
              if (!isValidData(ids)) {
                return onError(invalidFileContentsLocalized);
              }
              setSubmissionIds(result.data.map((e) => Object.values(e)[0]));
            },
            error: (error) => {
              console.error("Error parsing CSV file:", error.message);
            },
          });
        }
      } catch (error) {
        console.error("Error parsing file:", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDrop = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop();
      return acceptedFileTypes.includes(fileExtension.toLowerCase());
    });

    if (validFiles.length > 0) {
      const firstValidFile = validFiles[0];
      setFileName(firstValidFile.name);
      handleFileParsing(firstValidFile);
    } else {
      onError(invalidFileExtensionLocalized);
      setFileName("");
    }
  };
  const handleOnStart = () => {
    if (submissionIds && submissionIds.length > 0 && fileName) {
      // create report request object
      const request = {
        name: reportName,
        submissions: submissionIds,
      };
      if (date && moment(date).isValid()) {
        request.end_date = moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]");
      }
      create(request);
    }
  };

  const handleOnDelete = () => {
    onError(undefined);
    setFileName("");
  };

  return (
    <S.ExportsContainer>
      <S.InnerContainer>
        <S.DescriptionContainer>
          <S.DescriptionTitle>
            <Typography className="proSemibold18x24" color={Colors.NightRider}>
              {createAnAuditPackageLocalized}
            </Typography>
          </S.DescriptionTitle>
          <S.DescriptionText>
            <Typography className="proRegular15x21" color={Colors.Mortar}>
              {descriptionLocalized}
            </Typography>
          </S.DescriptionText>
        </S.DescriptionContainer>
        <S.DropzoneContainer>
          <S.FieldHead>
            <S.Badge>1</S.Badge>
            <S.FieldHeadTitle>
              <Typography className="proRegular15x21" color={Colors.NightRider}>
                {uploadSubmissionLocalized}
              </Typography>
            </S.FieldHeadTitle>
            <S.InfoIcon>
              <IconPopover
                icon={icons.INFO}
                iconSize={15}
                shouldBeFixed={false}
                showOnHover={false}
                title=""
                xDir="right"
                yPos="top"
                id="info_icon"
              >
                <S.PopoverContainer>
                  <S.PopoverTitle>
                    {whereDoIFindSubmissionIdsLocalized}
                  </S.PopoverTitle>
                  <S.PopoverBody>
                    <S.PopoverItem>
                      <ExternalLink
                        linkText="Funds Tracking Export (preferred)"
                        to="https://submittable.help/en/articles/3393858-using-the-funds-feature#h_fa8d0832ec"
                      />
                    </S.PopoverItem>
                    <S.PopoverItem>
                      <ExternalLink
                        linkText="Standard Reporting"
                        to="https://submittable.help/en/articles/1560978-standard-reports"
                      />
                    </S.PopoverItem>
                    <S.PopoverItem>
                      <ExternalLink
                        linkText="Advanced Reporting"
                        to="https://submittable.help/en/articles/4800293-how-do-i-report-on-funds-in-advanced-reporting"
                      />
                    </S.PopoverItem>
                  </S.PopoverBody>
                </S.PopoverContainer>
              </IconPopover>
            </S.InfoIcon>
            <S.DeleteIcon onClick={handleOnDelete}>
              <img src={deleteImg} alt="delete" />
            </S.DeleteIcon>
          </S.FieldHead>
          <Dropzone
            onDrop={handleDrop}
            accept={{
              "text/csv": [".csv"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <S.DropzoneContentContainer isValidFile={fileName}>
                    <>
                      {!fileName && (
                        <>
                          <S.DragAndDropHere>
                            {dragAndDropHereLocalized}
                          </S.DragAndDropHere>
                          <S.ChooseFile>{chooseFileLocalized}</S.ChooseFile>
                        </>
                      )}
                      {fileName && (
                        <>
                          <S.FileName>{fileName}</S.FileName>
                          <img src={checkMarkImg} alt="check" />
                        </>
                      )}
                    </>
                  </S.DropzoneContentContainer>
                </div>
              </section>
            )}
          </Dropzone>
          <S.DropzoneFooter>
            {acceptedFormatsLocalized}
          </S.DropzoneFooter>
        </S.DropzoneContainer>
        <S.NameContainer>
          <S.FieldHead>
            <S.Badge disabled={!fileName}>2</S.Badge>
            <S.FieldHeadTitle>
              <Typography
                className="proRegular15x21"
                color={!fileName ? Colors.VeryLightGrey : Colors.NightRider}
              >
                {nameYourAuditPackageLocalized}
              </Typography>
            </S.FieldHeadTitle>
          </S.FieldHead>
          <TextInput
            placeholder="Audit Folder Name"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
            disabled={!fileName}
          />
        </S.NameContainer>
        <S.FiscalYearContainer>
          <S.FieldHead>
            <S.Badge disabled={!fileName}>3</S.Badge>
            <S.FieldHeadTitle>
              <Typography
                className="proRegular15x21"
                color={!fileName ? Colors.VeryLightGrey : Colors.NightRider}
              >
                {selectFiscalYearLocalized}
              </Typography>
            </S.FieldHeadTitle>
          </S.FieldHead>
          <SingleDatePicker
            disabled={!fileName}
            date={date}
            showDefaultInputIcon
            placeholder=""
            onDateChange={onDateChange}
            focused={focused}
            onFocusChange={onFocusChange}
            id="date_picker"
            numberOfMonths={1}
            isOutsideRange={() => false}
          />
        </S.FiscalYearContainer>
        <S.ActionsContainer>
          <Button
            disabled={!fileName || message}
            buttonClass="start-my-export-button"
            buttonText="Start My Export"
            type={buttonTypes.info}
            buttonIconRight={icons.NEXT}
            onClick={handleOnStart}
            isLoading={isLoading}
          />
        </S.ActionsContainer>
      </S.InnerContainer>
    </S.ExportsContainer>
  );
};

export default SingleAudit;
