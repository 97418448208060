import React from 'react';


// Styles
import S from './style';

const Filter = ({ filters, onChange, onDelete, options }) => {

    return (
        <S.FilterContainer>
            <S.TagContainer>
                {/* {filters && [...filters]?.map(filter => <S.Tag key={filter}><span>{filter}</span><S.TagClose onClick={() => onDelete && onDelete(filter)} > <Icon
                    title={"Remove"} icon={icons.CLOSE}
                /></S.TagClose></S.Tag>)} */}
            </S.TagContainer>
            <S.DropdownContainer>
                {/* <Dropdown
                    value={filters}
                    label={options.label || 'Filter'}
                >
                    {options?.filters?.map(option => <button key={option} value={option} onClick={() => onChange && onChange(option)}>{option}</button>)}
                </Dropdown> */}
            </S.DropdownContainer>
        </S.FilterContainer>
    );
}

export default Filter;