import * as Sentry from '@sentry/react'
import { datadogLogs } from '@datadog/browser-logs'
import { DATADOG_CLIENT_ID, DATADOG_SERVICE, SENTRY_DSN } from '../config'

let isInitialized = false
const isProductionEnv = process.env.NODE_ENV === 'production'

/**
 * Options that are used to add context to logged event in sentry.io
 *
 * @see {@link https://develop.sentry.dev/sdk/event-payloads/contexts/|Contexts}
 * @typedef {Object} ScopeOptions
 * @property {Object.<string, string>} tags Tags are key/value pairs assigned to events that can be used for breaking down issues or quick access to finding related events.
 * @property {Object.<string, string>} extra Extra information that will be sent as extra data along with the event, and will be recorded as info.
 */

/**
 * Initializes Sentry SDK in production
 *
 * @see {@link https://docs.sentry.io/error-reporting/configuration/?platform=browser|Configuration}
 */
function configureSentry() {
  if (isInitialized) return
  const environment = process.env.NODE_ENV
  // Sentry config
  // https://docs.sentry.io/sdks/javascript/config/basics/
  let config = {
    dsn: SENTRY_DSN,
    sampleRate: 0.5, // https://docs.sentry.io/platforms/javascript/guides/react/config/filter/#sampling
    denyUrls: [
      // local development
      /submishmash\.com/i,
      /example\.com/i,
      /submittable\.local/i,
      // FB flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // PubNub
      /pndsn\.com/,
      // User downloaded HTML
      /^file:\/\/\//i,
      // Hotjar
      /hotjar\.com/i,
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Deploy Error
      'ChunkLoadError',
      // PDF borked
      'InvalidPDFException',
      // PDF password failed
      'PasswordException',
      // PubNub disconnected client
      'PubNub call failed, check status for details',
    ],
    environment,
  }
  // Add manager-front release if there is one. Otherwise use manager backend
  // TODO: Remove check for backend version once env is verified
  const gitRelease =
    process.env.FE_GIT_VERSION ||
    document.getElementsByName('gitRelease')[0]?.content ||
    'NOT FOUND'
  if (gitRelease.length) {
    config = {
      ...config,
      release: gitRelease,
    }
    window.gitRelease = gitRelease
  }

  Sentry.init(config)
  isInitialized = true
}

function configureDataDog() {
  const gitRelease =
    process.env.FE_GIT_VERSION ||
    document.getElementsByName('gitRelease')[0]?.content ||
    'NOT FOUND'

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_ID,
    site: 'datadoghq.com',
    service: DATADOG_SERVICE,
    version: gitRelease,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })
}

function configureLogging() {
  configureSentry()
  configureDataDog()
}

/**
 * Configures the scope of an event that will be sent to Sentry
 *
 * @see {@link https://docs.sentry.io/enriching-error-data/scopes/?platform=browser|Configuring the Scope}
 * @param {ScopeOptions} options Adds context to the recorded event
 */
function setScope(options) {
  /** @type {Sentry.User} */
  const user = window.__userInfo // eslint-disable-line no-underscore-dangle
  Sentry.configureScope(scope => {
    if (user) {
      const { userId: id, userPermissionLevel: permissionlevel } = user
      scope.setUser({
        id,
        permissionlevel,
      })
    }
    if (options.tags) {
      Object.keys(options.tags).forEach(tag => {
        scope.setTag(tag, options.tags[tag])
      })
    }
    if (options.extra) {
      scope.setExtra('info', options.extra)
    }
  })
}

/**
 * Sends a captured exception into Sentry (production) or prints it onto the console (development)
 *
 * @param {string|object} error Message to be recorded into Sentry.
 * @param {ScopeOptions} [options={}] Object containing extra information about the event to be logged into Sentry
 */
export const logException = (error, options = {}) => {
  if (isProductionEnv) {
    configureLogging()
    setScope(options)
    Sentry.captureException(error)
    datadogLogs.logger.error(error)
  } else {
    /* eslint-disable no-console */
    console.group('Sentry')
    console.info(
      'If we were in production the following error would be logged to sentry and datadog:'
    )
    console.error(error, options)
    console.groupEnd()
  }
}

/**
 * Sends a captured message into Sentry (production) or prints it onto the console (development)
 *
 * @param {string} message Message to be recorded into Sentry.
 * @param {ScopeOptions} [options={}] Object containing extra information about the event to be logged into Sentry.
 * @param {Sentry.Severity} [level='debug'] Sets the severity of the event.
 * @param {number} [throttlePercent=100] Number used to calculate the percentage of errors to be logged into Sentry.
 */
export const logMessage = (message, options = {}, level = 'debug', throttlePercent = 100) => {
  if (isProductionEnv) {
    if (Math.random() < throttlePercent / 100) {
      configureLogging()
      setScope(options)
      Sentry.captureMessage(message, level)

      // Now log to Datadog
      switch (level) {
        case 'debug':
          datadogLogs.logger.debug(message)
          break
        case 'fatal':
          datadogLogs.logger.error(message)
          break
        case 'error':
          datadogLogs.logger.error(message)
          break
        case 'warning':
          datadogLogs.logger.warn(message)
          break
        default:
          datadogLogs.logger.info(message)
      }
    }
  } else {
    /* eslint-disable no-console */
    console.group('Sentry')
    console.info('If we were in production the following message would be logged to sentry:')
    console.log(message, options)
    console.groupEnd()
  }
}

/**
 * Helper function used to log a fatal error message into Sentry.
 *
 * @param {string} message the message you want to log into Sentry
 * @param {ScopeOptions} options Object containing additional tags or extras
 * @returns {Function} logMessage with level set to fatal
 */
export const logFatal = (message, options = {}) => logMessage(message, options, 'fatal')

/**
 * Helper function used to log an error message into Sentry.
 *
 * @param {string} message the message you want to log into Sentry
 * @param {ScopeOptions} [options={}] Object containing additional tags or extras
 * @returns {Function} logMessage with level set to error
 */
export const logError = (message, options = {}) => logMessage(message, options, 'error')

/**
 * Helper function used to log a warning message into Sentry.
 *
 * @param {string} message the message you want to log into Sentry
 * @param {ScopeOptions} [options={}] Object containing additional tags or extras
 * @returns {Function} logMessage with level set to warning
 */
export const logWarning = (message, options = {}) => logMessage(message, options, 'warning')

/**
 * Helper function used to log an info message in Sentry.
 *
 * @param {string} message the message you want to log into Sentry
 * @param {ScopeOptions} [options={}] Object containing additional tags or extras
 * @returns {Function} logMessage with level set to info
 */
export const logInfo = (message, options = {}) => logMessage(message, options, 'info')

/**
 * Helper function used to log a debug message in Sentry.
 *
 * @param {string} message the message you want to log into Sentry
 * @param {ScopeOptions} [options={}] Object containing additional tags or extras
 * @returns {Function} logMessage with level set to debug
 */
export const logDebug = (message, options = {}) => logMessage(message, options, 'debug')

/**
 * Logs exceptions into sentry.io
 *
 * @param {string} module name of the module associated with a redux store
 * @returns {function} an enhancer that can be used with Redux's createStore
 * @see https://docs.sentry.io/platforms/javascript/react/#redux
 */
export const sentryReduxEnhancer = module => {
  configureLogging()
  return Sentry.createReduxEnhancer({
    configureScopeWithState: scope => {
      scope.setTag('module', module)
    },
  })
}

export default configureLogging