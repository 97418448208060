import styled from "styled-components";
import Colors from "../../design-system/colors";
import { Spacing } from "../../design-system/spacing";
import { InternalLink } from "@submittable/ui-components";

const S = {};

S.Container = styled.div`
  margin-left: ${Spacing.xxxl};
`;

S.InnerContainer = styled.div`
  min-height: 100vh;
  width: 100%;
`;

S.DataRefreshLabel = styled.div`
  width: 100%;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #555555;
  margin-bottom: 22px;
`;

S.DropdownName = styled.div`
  margin-top: ${Spacing.xxxxl};
  padding-left: 11px;
`;

S.GoBack = styled(InternalLink)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  margin-bottom: ${Spacing.xl};
  padding-left: 10px;
  width: fit-content;
  text-decoration-line: none;
`;

S.GoBackText = styled.span`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${Colors.Mortar};
`;

S.HeaderContainer = styled.div`
  margin-top: ${Spacing.NavBarHeight};
  padding-top: ${Spacing.xxxl};
`;

S.Title = styled.h5`
  color: ${Colors.NightRider};
  padding-left: 20px;
  font-size: 1.5rem;
`;

S.WidgetContainer = styled.div`
  border: 1px solid ${Colors.VeryLightGrey};
  height: 100%;
`;

S.FilterContainer = styled.div`
  border: 1px solid ${Colors.VeryLightGrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

S.NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
`;

// Dashboard Name

S.DashboardNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 586px;
`;

S.DashboardNameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 586px;
  color: ${Colors.NightRider};
`;

S.EditDashboardNameText = styled.div`
  display: flex;
  align-items: center;
`;

S.DashboardNameTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13.5px;
  max-width: 586px;
  height: 13px;
  font-weight: 500;
  color: ${Colors.NightRider};
  padding-left: 10px;
`;

S.DashboardDescContainer = styled.div`
  margin-top: 12px;
  width: 464px;
  min-height: 35px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${Colors.Mortar};
`;

S.DashboardDescSpan = styled.span`
  color: blue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

S.EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
`;

S.InputGroup = styled.div`
  display: flex;
`;

S.EditInputTitle = styled.input`
  box-sizing: border-box;
  width: 584px;
  height: 39px;
  background: ${Colors.White};
  border: 1px solid #04a1e5;
  box-shadow: 0px 0px 2px ${Colors.IrisBlue};
  border-radius: 3px;
  padding: 16px 52px 15px 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  ::placeholder {
    color: ${Colors.Empress};
  }
  color: ${Colors.NightRider};
`;

S.EditInputTitleCounter = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 34px;
  height: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${Colors.Empress};
  margin: 16px -42px 15px;
`;

S.EditInputDesc = styled.textarea`
  box-sizing: border-box;
  width: 584px;
  height: 69px;
  background: ${Colors.White};
  border: 1px solid ${Colors.VeryLightGrey};
  box-shadow: 0px 0px 2px ${Colors.IrisBlue};
  border-radius: 3px;
  padding: 16px 18px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  ::placeholder {
    color: ${Colors.Empress};
  }
  color: ${Colors.NightRider};
`;

S.EditInputDescCounter = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 37px;
  height: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${Colors.Empress};
  margin: 50px -45px 11px;
`;

S.EditSubmit = styled.button`
  box-sizing: border-box;
  width: 67px;
  height: 30px;
  background: ${Colors.White};
  border: 1px solid ${Colors.VeryLightGrey};
  border-radius: 3px;
  align-self: flex-end;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.NightRider};
  &:hover {
    background-color: ${Colors.White};
    cursor: pointer;
  }
`;

S.Divider = styled.div`
  border-bottom: 1px solid ${Colors.VeryLightGrey};
  width: calc(100% - 10px);
  margin: 12px 0 12px 11px;
`;

export default S;
