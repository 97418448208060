export const Spacing = {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
    xxl: "40px",
    xxxl: "48px",
    xxxxl: "56px",
    xxxxxl: "64px",
    SideNavCondensedWidth: "60px",
    NavBarHeight: "50px",
}
