import styled from "styled-components";

const S = {}

S.Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2); 
`;

S.Title = styled.h2`
    margin-bottom: 10px;
`;

S.InnerContainer = styled.h3`
    font-size: 2.5rem;
    margin-top: 60px;
    color: #666;
`;

S.FilterContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 10px 25px 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    `;




export default S;