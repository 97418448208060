import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import camelCase from 'lodash/camelCase'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import snakeCase from 'lodash/snakeCase'
// import isEmpty from 'lodash/isEmpty'

/** Works like Array.prototype.filter but on objects
 * @param {Object} obj - object to filter
 * @param {function} cb - if returns true item stays else isn't copied passed (key, value)
 * @returns {Object} - the new object!
 */
export const objectFilter = (obj, cb) => {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    const val = obj[key]
    if (cb(key, val)) newObj[key] = val
  })
  return newObj
}

/**
 * This is the callback for the object loop.
 * It returns void hence the name objectLoopAction
 * @callback objectLoopAction
 * @param {string} key - the key of the element we are on
 * @param {*} value - the value of the element we are on
 * @returns void
 */

/**
 * Loops through an objects keys, passed to a forEach loop,
 * allows for mutation but returns new Object
 * @param {Object} obj - object to loop
 * @param {objectLoopAction} cb - callback passed each key and value
 * @returns {Object}
 */

export const objectLoop = (obj, cb) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach(key => cb(key, newObj[key]))
  return newObj
}

/**
 * Sorts an object alphabetically by key
 * @param {Object} obj - object to sort
 * @returns {Object} - object sorted alphabetically
 */

export const sortObjKeysAlphabetically = obj =>
  Object.keys(obj)
    .sort()
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {})

const errorMessage = (funcName, accept, val) =>
  `The function ${funcName} only ${accept} a object. You passed in a ${JSON.stringify(val)}`

const encodeObjectErrorMessage = value => errorMessage('encodeObject', 'object', value)
const decodeObjectErrorMessage = value => errorMessage('decodeObject', 'string', value)

/**
 * Encodes a object into a base 64 string
 * @param {Object} obj - any type of object
 * @returns {string} - a base64 encoded string
 */
export const encodeObject = obj => {
  if (!isObject(obj) || isArray(obj)) throw new Error(encodeObjectErrorMessage(obj))
  return btoa(JSON.stringify(obj))
}

/**
 * Decodes a base64 encoded object
 * @param {string} base64Str - a base64 encoded object
 * @returns {Object}
 */
export const decodeObject = base64Str => {
  if (!isString(base64Str)) throw new Error(decodeObjectErrorMessage(base64Str))
  return JSON.parse(atob(base64Str))
}

/**
 * Renames a key in an object
 * @param {Object} object - the object with the key you want to rename
 * @param {string} oldKey - the key you want to rename
 * @param {string} newKey - the updated key name
 * @returns {Object}
 */
export const renameObjectKey = (object, oldKey, newKey) => {
  const { [oldKey]: item, ...rest } = object
  return { ...rest, [newKey]: item }
}

/**
 * Renames a key in an object
 * @param {Object or Array} item - the object or array with the key you want to camelcase
 * @returns {Object or Array}
 */
export const camelCaseKeysRecursively = item => {
  if (isArray(item)) {
    return item.map(value => camelCaseKeysRecursively(value))
  }
  if (item !== null && isObject(item)) {
    return Object.keys(item).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelCaseKeysRecursively(item[key]),
      }),
      {}
    )
  }
  return item
}

/**
 * Flattens a nested object recursively for API calls
 * @param {Object} object - the object you need to flatten
 * @returns {Object}
 */
export const flattenObjectForApiCall = object => {
  const newObject = {}
  Object.keys(object).forEach(key1 => {
    if (
      isArray(object[key1]) ||
      isString(object[key1]) ||
      isBoolean(object[key1]) ||
      isNumber(object[key1])
    ) {
      newObject[key1] = object[key1]
      return
    }
    if (isObject(object[key1])) {
      const flatObject = flattenObjectForApiCall(object[key1])
      Object.keys(flatObject).forEach(key2 => {
        newObject[`${key1}.${key2}`] = flatObject[key2]
      })
    }
  })
  return newObject
}

/**
 * Snake_cases object keys
 * @param {Object} obj - object to sort
 * @returns {Object} - object with keys snake_case
 */
export const snakeCaseObjectKeys = obj =>
  Object.keys(obj).reduce((newObj, key) => {
    // eslint-disable-next-line no-param-reassign
    newObj[snakeCase(key)] = obj[key]
    return newObj
  }, {})

/**
 * Flattens a nested object recursively and checks if values are falsy
 * @param {Object} object - the object you need to flatten and check values
 * @returns {Object}
 */
export const areAllObjectValuesFalsy = object => {
  const flatObj = flattenObjectForApiCall(object)
  const areValuesFalsy = Object.values(flatObj).every(
    val => val === '' || val.length === 0 || val === false || val === null || val === undefined
  )
  return areValuesFalsy
}

/**
 * Checks object to see if it contains a specific key
 * @param {Object} object - the object you need to check key values of
 * @param {String} key - the key you are looking for
 * @returns {boolean}
 */
export const doesObjectContainKey = (object, key) => Boolean(object[key])

/**
 * Return object key based on value associated with key
 * @param {Object} object - the object you need to find key in
 * @param {String} value - the value associated with the key you are looking for
 * @returns {string}
 */

export const getObjectKeyFromValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value)