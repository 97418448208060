import { InternalLink } from "@submittable/ui-components";
import styled from "styled-components";
import Colors from "../../../design-system/colors";

const S = {};

S.Container = styled.div`
  height: 100%;
  background: ${Colors.GreyF9};
  width: ${(props) => (props.collapsed ? "60px" : "220px")};
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 105px;
  padding-bottom: 16px;
  padding-right: ${(props) => (props.collapsed ? 0 : "16px")};
  padding-left: ${(props) => (props.collapsed ? 0 : "16px")};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.collapsed ? "center" : "flex-start")};
`;
S.ExpandCollapseButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  :hover {
    background: ${Colors.WhiteSmoke};
    border-radius: 50%;
  }
`;
S.MenuItem = styled.button`
  color: ${Colors.Charcoal};
  border-radius: 16px;
  width: 50%;
`;

S.MenuItemContainer = styled.div`
  width: ${(props) => (props.collapsed ? "38px" : "100%")};
  height: 38px;
  display: flex;
  justify-content: ${(props) => (props.collapsed ? "center" : "flex-start")};
  align-items: center;
  gap: 10px;
  color: ${Colors.NightRider};
  box-sizing: border-box;
  padding: ${(props) => (props.collapsed ? "0" : "0 10px 0 15px")};
  cursor: pointer;
  :hover {
    background: ${Colors.GreyEEE};
    border-radius: ${(props) => (props.collapsed ? "50%" : "31px")};
    max-width: 190px;
  }
`;
S.DashboardsItemContainer = styled.div`
  margin-top: 35px;
  width: ${(props) => (props.collapsed ? "38px" : "100%")};
  height: 38px;
`;

S.ExportsItemContainer = styled.div`
  margin-top: 25px;
  width: ${(props) => (props.collapsed ? "38px" : "100%")};
  height: 38px;
`;
S.HelpItemContainer = styled.div`
  width: ${(props) => (props.collapsed ? "38px" : "100%")};
  height: 38px;
  position: fixed;
  bottom: 100px;
`;
S.Title = styled.div``;

S.HeaderContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  box-sizing: border-box;
  height: 50px;
  padding: 16px 32px 32px 32px;
`;

S.FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  height: 80px;
  border: 1px solid ${Colors.WhiteSmoke};
`;

S.HomeButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

S.NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: ${Colors.WhiteSmoke};
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  z-index: 200;
  position: fixed;
  padding-right: 16px;
`;

S.LeftSide = styled.div`
  height: 100%;
  background: ${Colors.White};
  display: flex;
  align-items: center;
`;

S.RightSide = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding-right: 16px;
`;

S.SystemNameSelect = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer;
`;

S.SystemNameText = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
`;

S.SystemNameDropdown = styled.div`
  position: absolute;
  width: 220px;
  top: 50px;
  left: 0;
  background: ${Colors.White};
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 3px 3px;
`;

S.SystemNameDropdownItem = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer;
  color: ${(props) => (props.hovered ? Colors.White : Colors.NightRider)};
  background: ${(props) => (props.hovered ? Colors.Nordic : Colors.White)};
`;

S.ProfileContainer = styled.div`
  height: 38px;
  display: flex;
  gap: 14px;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 45px;
`;

S.ProfileText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 53px;
  background: #1a1a1a;
  color: ${Colors.White};
`;

S.ProfileDropdown = styled.div`
  width: 220px;
  border-radius: 3px;
  background: ${Colors.White};
  border: 1px solid ${Colors.GreyEEE};
`;

S.TopContainer = styled.div`
  padding-top: 19px;
  padding-bottom: 16px;
`;

S.SignedInAsText = styled.div`
  font-weight: 600;
  margin-bottom: 12px;
  padding-left: 10px;
`;

S.EmailText = styled.div`
  margin-bottom: 8px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
`;
S.LogoutLinkContainer = styled.div``;

S.BasicLink = styled(InternalLink)`
  color: #015b87;
`;

S.BottomContainer = styled.div``;

S.ProfileDivider = styled.div`
  border: 1px solid #eeeeee;
`;

S.TeamAccountsText = styled.div`
  margin-top: 12px;
  margin-left: 10px;
  margin-bottom: 12px;
`;

S.TeamLink = styled.div`
  padding: 9px 0 9px 18px;
  &&:hover {
    background: ${Colors.GreyF9};
  }
`;
S.TeamNameLine1 = styled.div`
  margin-bottom: 8px;
`;

S.TeamNameLine2 = styled.div`
  display: flex;
  align-items: center;
`;
export default S;
