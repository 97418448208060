import React from "react";

import { endpoint } from "../../../lib/constants";
// Redux
import { useGetMyUserQuery } from "../../../redux/user-slice";
// Components
import {
  Dropdown,
  LoadingSpinner,
  useLocalize,
} from "@submittable/ui-components";
import Typography from "@mui/material/Typography";
// Styles
import S from "./style";
import Colors from "../../../design-system/colors";

const Profile = () => {
  const { data: user, isLoading } = useGetMyUserQuery();
  const localizedTeamAccounts = useLocalize`Team Accounts`;

  return (
    <S.ProfileContainer>
      <Dropdown
        openLeft={true}
        labelComponent={
          <S.ProfileText>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Typography className="proRegular13x17" color={Colors.White}>
                {`${user?.firstName?.[0] || ""}${user?.lastName?.[0] || ""}`}
              </Typography>
            )}
          </S.ProfileText>
        }
        role="menu"
      >
        <div>
          {user && (
            <S.ProfileDropdown>
              <S.TopContainer>
                <S.SignedInAsText>
                  <Typography
                    className="proRegular13x17x600"
                    color={Colors.NightRider}
                  >
                    Signed in as:
                  </Typography>
                </S.SignedInAsText>
                <S.EmailText>
                  <Typography className="proRegular13x17" color={Colors.Mortar}>
                    {user.email}
                  </Typography>
                </S.EmailText>
                <S.LogoutLinkContainer>
                  <S.BasicLink
                    to={`${endpoint}/logout`}
                    linkText={"Logout"}
                    className="proLink15x21"
                  />
                </S.LogoutLinkContainer>
              </S.TopContainer>
              <S.ProfileDivider />
              <S.TeamAccountsText>
                <Typography
                  className="proSemibold15x16"
                  color={Colors.NightRider}
                >
                  {localizedTeamAccounts}
                </Typography>
              </S.TeamAccountsText>
              <S.ProfileDivider />
              <S.BottomContainer>
                {user.organizations?.map((org) => (
                  <S.BasicLink
                    key={`org${org.legacyOrganizationId}`}
                    to={`https://${org.domain}/dataportal/dashboards`}
                  >
                    <S.TeamNameLine1>
                      <Typography
                        className="proRegular13x17"
                        color={Colors.NightRider}
                      >
                        {org.organizationName}
                      </Typography>
                    </S.TeamNameLine1>
                    <S.TeamNameLine2>
                      <Typography
                        className="proRegular11x24"
                        color={Colors.Mortar}
                      >
                        {`Account ID - ${org.legacyOrganizationId}`}
                      </Typography>
                    </S.TeamNameLine2>
                  </S.BasicLink>
                ))}
              </S.BottomContainer>
            </S.ProfileDropdown>
          )}
        </div>
      </Dropdown>
    </S.ProfileContainer>
  );
};

export default Profile;
