import styled, { css } from "styled-components";
import Colors from "../../design-system/colors";
import { Spacing } from "../../design-system/spacing";
import { InternalLink } from "@submittable/ui-components";

const S = {};

S.Container = styled.div`
  min-height: 100vh;
  width: 90%;
  padding-left: 62px;
`;

S.Header = styled.div`
  margin-top: 75px;
  margin-bottom: 77px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

S.GoBack = styled(InternalLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-decoration-line: none;
`;

S.AlertContainer = styled.div`
  flex-grow: 1;
`;

S.GoBackText = styled.div`
  /* 15x21 Regular */
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 140% */
  color: ${Colors.Cerulean};
`;

S.TableTitle = styled.div`
  margin-bottom: 23px;
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

S.TableContainer = styled.div`
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 0px 0px 3px 3px;
`;

S.Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
`;

S.TableHeader = styled.thead`
  height: 35px;
  background: ${Colors.WhiteSmoke};
  letter-spacing: -0.011em;
  color: ${Colors.Mortar}
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 136.5%;
`;

S.TableHeaderRow = styled.tr`
  border-bottom: 1px solid ${Colors.VeryLightGrey};
  color: #555;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 106.667% */
`;

S.TitleHeader = styled.td`
  padding-left: 32px;
  width: 24%;
  color: ${Colors.Mortar};
`;

S.TypeHeader = styled.td`
  text-align: left;
  width: 20%;
  font-weight: 600;
  color: ${Colors.Mortar};
`;

S.DateCreatedHeader = styled.td`
  text-align: left;
  width: 16%;
  color: ${Colors.Mortar};
`;

S.StatusHeader = styled.td`
  text-align: left;
  width: 25%;
  color: ${Colors.Mortar};
`;

S.ActionHeader = styled.td`
  min-width: 5%;
`;

S.NoTableFoundDivision = styled.td``;

S.NoDownloadsFound = styled.div`
  height: 55px;
  width: 100%;
  padding-left: 23px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #737373;
  margin: 0;
`;
S.DashboardsTitle = styled.div`
  padding-top: calc(51px + ${Spacing.NavBarHeight});
  margin-bottom: 92px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${Colors.Mortar};
`;

S.ListContainer = styled.div`
  width: 89%;
`;

S.DashboardsContainer = styled.div`
  padding-top: 60px;
  width: 100%;
`;

S.TableRow = styled.tr`
  ${(props) =>
    !props.last &&
    css`
      border-bottom: 1px solid ${Colors.VeryLightGrey};
    `}
`;

S.Title = styled.td`
  color: #333;
  font-feature-settings: "clig" off, "liga" off;
  /* 15x21 Semibold */
  font-family: Proxima Nova;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 140% */
  padding-left: 32px;
  height: 100%;
  padding-bottom: 16px;
  padding-top: 16px;
`;

S.EditTitle = styled.td`
  padding-left: 15px;
  height: 100%;
  width: 100%;
  padding-bottom: 11px;
  padding-top: 18px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  gap: 8px;
`;

S.RenameButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-end;
`;

S.Type = styled.td`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  align-items: center;
  color: ${Colors.Charcoal};
  padding-bottom: 16px;
  padding-top: 16px;
`;

S.CreatedAt = styled.td`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: ${Colors.Charcoal};
`;

S.Status = styled.td`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: ${Colors.Charcoal};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.status === "In-progress" ? "column" : "row"};
  align-items: ${(props) =>
    props.status === "In-progress" ? "flex-start" : "center"};
  gap: 4px;
`;

S.InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 32px;
`;

S.HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

S.InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

S.AlertsTitle = styled.div`
  padding-top: calc(51px + ${Spacing.NavBarHeight});
  margin-bottom: 92px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${Colors.Mortar};
  width: 95%;
`;

S.ConfirmationText = styled.p`
  color: #333;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* 18x24 Semibold */
  font-family: Proxima Nova;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
`;

S.ConfirmationActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

S.MenuItem = styled.div`
  padding: 12px 32px 12px 15px;
  cursor: pointer;
  color: #333;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Proxima Nova;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */

  &:hover {
    background: #eee;
  }
`;

export default S;
