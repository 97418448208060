import React, { useEffect } from "react";

// Components
import { LoadingSpinner } from "@submittable/ui-components";
import Filter from "../Filter";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectFunds,
  selectLabels,
  selectSelectors,
  selectStatuses,
  setFunds,
  setLabels,
  appendSelectors,
  setStatus,
  setStatuses,
} from "../../redux/selector-slice.js";
// Styles
import S from "./style";

const allProjects = ["project 1", "project 2", "project 3"];
const allStatuses = ["awarded", "paid", "pending"];
const allLabels = ["label 1", "label 2", "label 3"];
const allFunds = ["fund 1", "fund 2", "fund 3"];

const SelectorTile = ({ widget }) => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const dispatch = useDispatch();
  const projects = useSelector(selectSelectors);
  const statuses = useSelector(selectStatuses);
  const labels = useSelector(selectLabels);
  const funds = useSelector(selectFunds);

  const handleAddProjects = (project) => {
    dispatch(setStatus("loading"));
    if (projects) {
      dispatch(appendSelectors([...projects, project]));
    } else {
      dispatch(appendSelectors([project]));
    }
  };

  const handleDeleteProject = (project) => {
    if (projects) {
      dispatch(appendSelectors(projects.filter((p) => p !== project)));
    }
  };

  const handleAddStatus = (status) => {
    dispatch(setStatus("loading"));
    if (statuses) {
      dispatch(setStatuses([...statuses, status]));
    } else {
      dispatch(setStatuses([status]));
    }
  };

  const handleDeleteStatus = (status) => {
    if (statuses) {
      dispatch(setStatuses(statuses.filter((p) => p !== status)));
    }
  };

  const handleAddLabel = (label) => {
    dispatch(setStatus("loading"));
    if (labels) {
      dispatch(setLabels([...labels, label]));
    } else {
      dispatch(setLabels([label]));
    }
  };

  const handleDeleteLabel = (label) => {
    if (labels) {
      dispatch(setLabels(labels.filter((p) => p !== label)));
    }
  };

  const handleAddFund = (fund) => {
    dispatch(setStatus("loading"));
    if (funds) {
      dispatch(setFunds([...funds, fund]));
    } else {
      dispatch(setFunds([fund]));
    }
  };

  const handleDeleteFund = (fund) => {
    if (funds) {
      dispatch(setFunds(labels.filter((p) => p !== fund)));
    }
  };

  return (
    <S.Container>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <S.Title>{widget.title}</S.Title>
          <S.FilterContainer>
            <Filter
              filters={projects}
              onChange={handleAddProjects}
              onDelete={handleDeleteProject}
              options={{ filters: allProjects, label: "Project name" }}
            />
            <Filter
              filters={statuses}
              onChange={handleAddStatus}
              onDelete={handleDeleteStatus}
              options={{ filters: allStatuses, label: "Submission status" }}
            />
            <Filter
              filters={labels}
              onChange={handleAddLabel}
              onDelete={handleDeleteLabel}
              options={{ filters: allLabels, label: "Label" }}
            />
            <Filter
              filters={funds}
              onChange={handleAddFund}
              onDelete={handleDeleteFund}
              options={{ filters: allFunds, label: "Funds" }}
            />
          </S.FilterContainer>
        </>
      )}
    </S.Container>
  );
};

export default SelectorTile;
