import styled from "styled-components";

const S = {};

S.Container = styled.div`
  width: 100%;
  height: 150px;
  background-color: #f5f5f5;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
`;

S.TopBar = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 8px;
  right: 6px;
`;

S.Value = styled.div``;

S.Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 12px;
`;

S.InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default S;
