import React from "react";

// Components
import { Typography } from "@mui/material";
import { useLocalize } from "@submittable/ui-components";

// Styles
import Colors from "../../design-system/colors";
import S from "../style";

const NoData = ({ widget }) => {
  const noDataLocalized = useLocalize`No Data`;

  return (
    <S.NoDataContainer>
      <Typography className="proSemibold18x24"> {widget.title} </Typography>
      <S.NoDataMessage>
        <Typography className="proRegular36x36" color={Colors.Mortar}>
          {noDataLocalized}
        </Typography>
      </S.NoDataMessage>
    </S.NoDataContainer>
  );
};

export default NoData;
