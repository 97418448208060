import styled from 'styled-components';

const S = {};

S.FilterContainer = styled.div`
dispay: flex;
justify-content: flex-end;
gap: 10px;
position: relative;
width: 100%;
margin-bottom: 20px;
padding-right: 10px;
`;

S.TagContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

S.Tag = styled.div`
    padding: 2px;
    border-radius: 16px;
    background: #eee;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    color: #333;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 12px;
`;

S.TagClose = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    transform: scale(0.5);
    color: red;
`;


S.DropdownContainer = styled.div`
position: absolute;
top: 0;
left: 0;
`;

export default S;