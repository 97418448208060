import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { endpoint } from "../lib/constants";
import { tokenReceived } from "./auth-slice";
import { gotoManagerLogin } from "./helpers";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `/`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.jwt;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  if (!api.getState().auth.jwt) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const retrieveResults = await retrieveJWT(api, extraOptions);
      if (retrieveResults.data) {
        api.dispatch(tokenReceived(retrieveResults.data));
        release();
      } else {
        return gotoManagerLogin();
      }
    } else {
      await mutex.waitForUnlock();
    }
  }

  try {
    let result = await baseQuery(args, api, extraOptions);
    if (
      (result.error && result.error.status === 401) ||
      (result.message && result.message.includes("denied"))
    ) {
      result = await RetryWithReauth(api, extraOptions, result, args);
    }
    return result;
  } catch (error) {}
};

async function retrieveJWT(api, extraOptions) {
  const result = await baseQuery(
    {
      url: `${endpoint}/api/tokens/forms`,
      method: "POST",
    },
    api,
    extraOptions
  );
  return result;
}

async function RetryWithReauth(api, extraOptions, result, args) {
  const response = await retrieveJWT(api, extraOptions);
  if (response.data) {
    api.dispatch(tokenReceived(response.data));
    result = await baseQuery(args, api, extraOptions);
  } else {
    return gotoManagerLogin();
  }
  return result;
}
