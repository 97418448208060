import React, { useState } from "react";

// Components
import { Box, Stack } from "@mui/material";
import { Dropdown } from "@submittable/ui-components";
// Router
import { endpoint } from "../../../lib/constants";
// Images
import submittableIcon from "../../../assets/images/submittable-icon.svg";
// Styles
import S from "./style";

const systems = [
  { name: "Data Portal", route: "/dataportal/dashboards" },
  { name: "Submittable", route: "/submissions" },
];

const ApplicationNameSelector = () => {
  const [selection, setSelection] = useState(systems[0].name);
  const [hoverText, setHoverText] = useState(systems[0].name);

  const handleChange = (loc) => {
    setSelection(systems.find((s) => s.route === loc).name);
    window.location.href = endpoint + loc;
  };

  return (
    <>
      <Dropdown
        labelComponent={
          <Stack
            direction={"row"}
            width={"175px"}
            spacing={1}
            alignItems={"center"}
            sx={{
              cursor: "pointer",
              paddingLeft: "16px",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <img
              src={submittableIcon}
              width={18}
              height={18}
              alt="submittable icon"
            />
            <S.SystemNameText>{selection}</S.SystemNameText>
          </Stack>
        }
        role="menu"
      >
        <Box style={{ minWidth: "200px" }}>
          {systems.map((system) => (
            <Stack
              key={system.name}
              direction={"row"}
              onClick={() => handleChange(system.route)}
              spacing={1}
              pl={2}
              sx={{
                cursor: "pointer",
                paddingTop: "8px",
                paddingBottom: "8px",
                backgroundColor: hoverText === system.name ? "#333" : "#FFF",
                color: hoverText === system.name ? "#FFF" : "#333",
                width: "200px",
                height: "38px",
                alignItems: "center",
              }}
              onMouseEnter={() => setHoverText(system.name)}
            >
              <img
                src={submittableIcon}
                width={18}
                height={18}
                alt="submittable icon"
              />
              <S.SystemNameText>{system.name}</S.SystemNameText>
            </Stack>
          ))}
        </Box>
      </Dropdown>
    </>
  );
};

export default ApplicationNameSelector;
