import React from 'react';
// Styles
import S from './style';

const Widget = ({ layout }) => <S.Container>
    <S.WidgetContainer>
        <div>
            {`[${layout.x}, ${layout.y}, ${layout.w}, ${layout.h}]`}
        </div>
        <div>
            {`i = ${layout.i} maxW = ${layout.maxW} minW = ${layout.minW}`}
        </div>
    </S.WidgetContainer>

</S.Container>

export default Widget;