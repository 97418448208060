import { GaCookiesPlugin } from "@snowplow/browser-plugin-ga-cookies";
import { PerformanceTimingPlugin } from "@snowplow/browser-plugin-performance-timing";
import { TimezonePlugin } from "@snowplow/browser-plugin-timezone";
import {
  addGlobalContexts,
  enableActivityTracking,
  newTracker,
  setUserId,
  trackPageView,
  trackSelfDescribingEvent,
} from "@snowplow/browser-tracker";
import { initPerfume } from "perfume.js";
import { SNOWPLOW_COLLECTOR_ENDPOINT } from "../config";
import { logException } from "./logging";
import { snakeCaseObjectKeys } from "./objectUtils";

const initSnowplow = (jwt) => {
  const jwtPayload = jwt.split(".")[1];
  const decodedJwt = JSON.parse(atob(jwtPayload));
  const {
    organizationId,
    permissionLevel: userPermissionLevel,
    legacyOrganizationId: adminId,
    sub: userId,
  } = decodedJwt;

  try {
    newTracker("data-browser-sp", SNOWPLOW_COLLECTOR_ENDPOINT, {
      appId: "data",
      platform: "web",
      discoverRootDomain: true,
      cookieSameSite: "Lax",
      cookieSecure: false,
      contexts: {
        webPage: true,
      },
      plugins: [PerformanceTimingPlugin(), TimezonePlugin(), GaCookiesPlugin()],
    });
    // eslint-disable-next-line no-underscore-dangle
    const email = null;
    const isImpersonating = false;

    const user = {
      userId,
      email,
      userPermissionLevel: parseInt(userPermissionLevel, 10) || null,
      isImpersonating,
    };
    const publisher = { organizationId, adminId };
    const userContext = {
      schema: "iglu:com.submittable.snowplow/user_context/jsonschema/1-0-3",
      data: snakeCaseObjectKeys(user),
    };

    const organizationContext = {
      schema:
        "iglu:com.submittable.snowplow/organization_context/jsonschema/1-0-3",
      data: snakeCaseObjectKeys(publisher),
    };

    addGlobalContexts([userContext, organizationContext]);
    setUserId(userId);
    enableActivityTracking({
      minimumVisitLength: 5,
      heartbeatDelay: 10,
    });
    trackPageView();

    window.sendSnowplowEvent = trackSelfDescribingEvent;

    // eslint-disable-next-line no-unused-vars
    const perfume = new initPerfume({
      analyticsTracker: ({ metricName, data, navigatorInformation }) => {
        if (
          metricName === "fp" ||
          metricName === "fcp" ||
          metricName === "lcp"
        ) {
          const dataset =
            typeof data === "object" && data !== null
              ? data
              : { [metricName]: data };
          trackSelfDescribingEvent({
            event: {
              schema:
                "iglu:com.submittable.snowplow/submittable_event/jsonschema/1-0-1",
              data: {
                submittableEventName: "perfume_performance_event",
                metricName,
                ...navigatorInformation,
                ...dataset,
              },
            },
          });
        }
      },
    });
  } catch (e) {
    logException(e, {
      tags: { module: "snowplowAnalytics" },
      extra: { publisher: window?.intercomSettings },
    });
  }
};

export const sendSnowplowEvent = (fieldData, eventType) => {
  const eventData = {
    eventType,
    eventContext: snakeCaseObjectKeys(fieldData),
  };

  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.submittable.snowplow/gms_event_context/jsonschema/1-0-1",
      data: snakeCaseObjectKeys(eventData),
    },
  });
};

export default initSnowplow;
