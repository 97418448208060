import React from "react";

// Components
import { LocalizeWidget } from "@submittable/ui-components";
import ApplicationNameSelector from "./application-name-selector";
import Profile from "./profile";
// Styles
import S from "./style";

const NavBar = () => {
  return (
    <S.NavBarContainer>
      <S.LeftSide>
        <ApplicationNameSelector />
      </S.LeftSide>
      <S.RightSide>
        <LocalizeWidget />
        <Profile />
      </S.RightSide>
    </S.NavBarContainer>
  );
};

export default NavBar;
