/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

// Components
import { LoadingSpinner, useLocalize } from "@submittable/ui-components";
import Notification from "../notification";
import Row from "./Row";
// redux
import { useGetQuery } from "../../redux/single-audit-slice";
// Images
import leftArrow from "../../assets/images/left-arrow.svg";
import logo from "../../assets/images/submittable-icon.svg";
// Styles
import S from "./style";

const Downloads = () => {
  const localizedType = useLocalize`Type`;
  const localizedDateCreated = useLocalize`Date Created`;
  const localizedStatus = useLocalize`Status`;
  const localizedTitle = useLocalize`Title`;
  const [error, setError] = useState(undefined);

  const { data, isLoading } = useGetQuery();

  const noDownloadsAvailableLocalized = useLocalize`No downloads available`;

  return (
    <S.Container>
      <S.Header>
        <S.AlertContainer>
          {error && (
            <S.AlertsTitle>
              <Notification
                message={error}
                onClose={() => setError(undefined)}
                type="info"
              />
            </S.AlertsTitle>
          )}
        </S.AlertContainer>
        <S.GoBack to="/dataportal/auditprep/exports">
          <img src={leftArrow} alt="go back" width={16} height={12} />
          <S.GoBackText>{useLocalize`Back to Exports`}</S.GoBackText>
        </S.GoBack>
      </S.Header>
      <S.TableTitle>My Downloads</S.TableTitle>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <S.TableContainer>
          <S.Table>
            <S.TableHeader>
              <S.TableHeaderRow>
                <S.TitleHeader>{localizedTitle}</S.TitleHeader>
                <S.TypeHeader>{localizedType}</S.TypeHeader>
                <S.DateCreatedHeader>
                  {localizedDateCreated}
                </S.DateCreatedHeader>
                <S.StatusHeader>{localizedStatus}</S.StatusHeader>
                <S.ActionHeader></S.ActionHeader>
                <S.ActionHeader></S.ActionHeader>
                <S.ActionHeader></S.ActionHeader>
              </S.TableHeaderRow>
            </S.TableHeader>
            <tbody>
              {data?.length === 0 ? (
                // Show a row with "No downloads found" message
                <tr>
                  <S.NoTableFoundDivision colSpan={5}>
                    <S.NoDownloadsFound>
                      <img src={logo} alt="logo" width={17} height={23} />
                      <span>{noDownloadsAvailableLocalized}</span>
                    </S.NoDownloadsFound>
                  </S.NoTableFoundDivision>
                </tr>
              ) : (
                data?.map((report, index) => (
                  <Row
                    key={report.id}
                    id={report.id}
                    index={index}
                    total={data.length}
                  />
                ))
              )}
            </tbody>
          </S.Table>
        </S.TableContainer>
      )}
    </S.Container>
  );
};

export default Downloads;
