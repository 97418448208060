import { createSlice } from "@reduxjs/toolkit";
import { gotoManagerLogout } from "./helpers";

const initialState = {
  jwt: "",
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.jwt = "";
      gotoManagerLogout();
    },
    tokenReceived: (state, action) => {
      state.jwt = action.payload;
    },
  },
});

export const { logout, tokenReceived } = authSlice.actions;
export default authSlice.reducer;
