import styled from "styled-components";

const S = {};

S.Container = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid #333;
`;


export default S;
