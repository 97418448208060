import styled from "styled-components";

const S = {};

S.Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1px;
`;

S.InnerContainer = styled.div``;

export default S;
