import React, { useState } from "react";

// Components
import { Dropdown } from "@submittable/ui-components";
// Styles
import S from "../style";
// Images
import crossIcon from "../../assets/images/cross-icon.svg";
import KebabMenu from "../../assets/images/kebab-menu-icon.svg";

const TopBar = ({
  widget,
  onExport,
  onToggleToolbar,
  isAdditionalToolbarVisible,
  isMovable = false,
}) => {
  const [open, setOpen] = useState(false);

  const isNumberTile = widget.type === "NUMBER_TILE";
  const isTable = widget.type === "TABLE";

  const handleOpenClick = () => {
    setOpen(!open);
  };

  const handleExportClick = (kind) => {
    onExport(kind);
    setOpen(false);
  };

  const handleViewFullscreen = () => {
    // select the nearest element in the DOM with the same id as the widget.id
    const widgetElement = document.getElementById(widget.id);
    // if the element exists, request fullscreen
    if (widgetElement) {
      widgetElement.requestFullscreen();
    }
    // close the menu
    setOpen(false);
  };

  const handleToggleToolbar = () => {
    if (onToggleToolbar) {
      onToggleToolbar();
    }
    setOpen(false);
  };
  return (
    <S.TopBar>
      <S.TopBarInner>
        {isMovable ? (
          <img src={crossIcon} alt="menu" width={13} height={13} />
        ) : (
          <div />
        )}
        <Dropdown
          hideCarret={true}
          openLeft={true}
          labelComponent={
            <S.MenuIcon onClick={handleOpenClick}>
              <img src={KebabMenu} alt="menu" width={13} height={13} />
            </S.MenuIcon>
          }
          role="menu"
        >
          {isTable && (
            <S.Menu>
              <S.MenuItem onClick={handleToggleToolbar}>
                {isAdditionalToolbarVisible ? `Hide` : `Show`} toolbar
              </S.MenuItem>
            </S.Menu>
          )}
          {!isTable && (
            <S.Menu>
              <S.MenuItem onClick={handleViewFullscreen}>
                View full screen
              </S.MenuItem>
              {!isNumberTile && (
                <>
                  <S.MenuSeparator></S.MenuSeparator>
                  <S.DownloadMenuItem>Download</S.DownloadMenuItem>
                  <S.MenuSeparator></S.MenuSeparator>
                </>
              )}

              <S.MenuItem onClick={() => handleExportClick("png")}>
                PNG
              </S.MenuItem>
              {!isNumberTile && (
                <S.MenuItem
                  onClick={() => handleExportClick("pdf")}
                  disabled={isNumberTile}
                >
                  PDF
                </S.MenuItem>
              )}
              <S.MenuItem onClick={() => handleExportClick("jpeg")}>
                JPEG
              </S.MenuItem>
              {!isNumberTile && (
                <>
                  <S.MenuItem
                    onClick={() => handleExportClick("svg")}
                    disabled={isNumberTile}
                  >
                    SVG
                  </S.MenuItem>
                  <S.MenuItem
                    onClick={() => handleExportClick("csv")}
                    disabled={isNumberTile}
                  >
                    CSV
                  </S.MenuItem>
                  <S.MenuItem
                    onClick={() => handleExportClick("xls")}
                    disabled={isNumberTile}
                  >
                    XLS
                  </S.MenuItem>
                </>
              )}
            </S.Menu>
          )}
        </Dropdown>
      </S.TopBarInner>
    </S.TopBar>
  );
};

export default TopBar;
